import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/image/Logo.png'
import './Header.scss'
import { IoMdLogIn } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiMenu3Fill } from "react-icons/ri";
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import inner_b3 from '../../assets/image/Logo.png'

import { CaretDownOutlined } from '@ant-design/icons';
import $ from "jquery";

export default function Header() {
  const [isNavbarMenuActive, setNavbarMenuActive] = useState(false);
  const [isNavbarsubMenuActive, setNavbarsubMenuActive] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const navbarMenu = document.getElementById("navbar");
  const overlayMenu = document.querySelector(".overlay");

  const toggleMenu = () => {
    setNavbarMenuActive(!isNavbarMenuActive);
    navbarMenu.classList.toggle("active");
    overlayMenu.classList.toggle("active");
  };

  const dropdowntogle = () => {
    // document.getElementById("myID").classList.add("show-example");
    $(".subdropdown .sub_sub-menu").toggleClass("show");
    // setNavbarsubMenuActive(!isNavbarsubMenuActive);
    // navbarMenu.classList.toggle("active");
    // overlayMenu.classList.toggle("active");
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("userid");
    setLoggedIn(!!storedUserId);
  }, []);

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("userid");
    setLoggedIn(false);
    window.location.href = '/';
  };

  const collapseSubMenu = () => {
    navbarMenu
      .querySelector(".menu-dropdown.active .submenu")
      .removeAttribute("style");
    navbarMenu.querySelector(".menu-dropdown.active").classList.remove("active");
  };

  const collapseSubdropdown = () => {
    navbarMenu
      .querySelector(".submenu-item.active .sub_sub-menu")
      .removeAttribute("style");
    navbarMenu.querySelector(".submenu-item.active").classList.remove("active");
  };

  const toggleSubMenu = (e) => {
    if (e.target.hasAttribute("data-toggle") && window.innerWidth <= 992) {
      e.preventDefault();
      const menuDropdown = e.target.parentElement;

      if (menuDropdown.classList.contains("active")) {
        collapseSubMenu();
      } else {
        if (navbarMenu.querySelector(".menu-dropdown.active")) {
          collapseSubMenu();
        }

        menuDropdown.classList.add("active");
        const subMenu = menuDropdown.querySelector(".submenu");
        subMenu.style.maxHeight = subMenu.scrollHeight + "px";
      }
    }
  };

  const resizeWindow = () => {
    if (window.innerWidth > 992) {
      if (isNavbarMenuActive) {
        toggleMenu();
      }
      if (navbarMenu.querySelector(".menu-dropdown.active")) {
        collapseSubMenu();
      }
      if (isNavbarsubMenuActive) {
        dropdowntogle();
      }
      if (navbarMenu.querySelector(".submenu-item.active")) {
        collapseSubdropdown();
      }
    }
  };
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);







  const [url, seturl] = useState('');
  const [school_logo, setschool_logo] = useState('');
  const onSubmit = async () => {
    try {
      let url = `https://merividhya.in/Admin/api/contact-us-details-front`;
      const response = await axios.post(url);
      const responseData = response.data;
      const SuperAdminLogo = response.data.SuperAdminLogo;
      seturl(SuperAdminLogo)

      if (responseData && responseData.data && responseData.data.length > 0) {
        const data = responseData.data[0];
   
        setschool_logo(data.site_logo);
        
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    } finally {
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  return (
    <>
      <header className={scroll ? "fixed-top-custum header" : "header"} id="header">
        <nav>
          <section className="wrapper container">

          <div className="logo mt-15 mb-15">
                 
                 {school_logo
                   ? <img src={`${url}/${school_logo}`} className="img-fluid" alt="Empower" style={{borderRadius:"50%"}} />
                   : <img src={inner_b3} className="img-fluid" alt="Fallback" />
                 }
               </div>


            <span className="overlay" onClick={toggleMenu}></span>
            {/* <nav className="navbar" id="navbar">
            <div className="mobilelogo">
              <img src={Logo} className="img-fluid" alt="Logo" />
            </div>
            <ul className="menu" id="menu">
            <li onClick={toggleMenu} className="menu-item"><Link to="/" className='menu-link'>Home</Link></li>
            <li onClick={toggleMenu} className="menu-item"><Link to="/Aboutus" className='menu-link'>About Us</Link></li>

              <li className="menu-item menu-dropdown" onClick={toggleSubMenu}>
                <span className="menu-link" data-toggle="submenu">Features <CaretDownOutlined /></span>
                <ul className="submenu">
                  <li onClick={dropdowntogle} className="submenu-item subdropdown"><span  className='submenu-link d-flex  justify-content-between '>Core Modules <RiArrowRightSLine /></span>
                    <ul className="sub_sub-menu">
                       <li  className="submenu-item"><Link to="/" className='submenu-link'>items 1</Link>
                       </li>
                    </ul>
                  </li>
                  <li onClick={toggleMenu} className="submenu-item"><Link to="/" className='submenu-link'> Integration </Link></li>
                 
                </ul>
              </li>
             
            <li onClick={toggleMenu} className="menu-item"><Link to="/" className='menu-link'>Become our partner</Link></li>
            <li onClick={toggleMenu} className="menu-item"><Link to="/Carrer" className='menu-link'>Careers</Link></li>
            <li onClick={toggleMenu} className="menu-item"><Link to="/Contactus" className='menu-link'>Contact Us</Link></li>
             
            



             
            </ul>
            
          </nav> */}

            <label htmlFor="menubrop" className="bartoggle">
              <RiMenu3Fill />

            </label>
            <input type="checkbox" id="menubrop" />
            <ul className="NavMenu">

              <li>
                <Link to="/" onClick={toggleMenu}>Home</Link>
              </li>
              <li>
                <Link to="/Aboutus" onClick={toggleMenu}>About Us</Link>
              </li>
              <li>
                <Link to="javascript:void(0)">
                  <label htmlFor="droplist1" className="toggle d-flex justify-content-between">
                    Features
                  </label>
                  <CaretDownOutlined />
                </Link>
                <input type="checkbox" id="droplist1" />
                {/* =============FirstDropDown================== */}
                <ul>

                  <li>
                    <Link to="javascript:void(0)">
                      <label htmlFor="droplist2" className="toggle">
                        Core Modules
                      </label>
                    </Link>
                    <input type="checkbox" id="droplist2" />
                    {/* =========Sub Drop Menu========== */}
                    <ul>
                      <li>
                        <Link to="#">Admission Management</Link>
                      </li>
                      <li>
                        <Link to="#">Attendance Management</Link>
                      </li>
                      <li>
                        <Link to="#">Calender</Link>
                      </li>
                      <li>
                        <Link to="#">Conveyance Management</Link>
                      </li>
                      <li>
                        <Link to="#">Payroll Management</Link>
                      </li>
                      <li>
                        <Link to="#">Profit & Loss Management</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="javascript:void(0)">
                      <label htmlFor="droplist3" className="toggle">
                        Integration
                      </label>
                    </Link>
                    <input type="checkbox" id="droplist3" />
                    {/* =========Sub Drop Menu========== */}
                    <ul>
                      <li>
                        <Link to="#">SMS / Email</Link>
                      </li>
                      <li>
                        <Link to="#">Teachers App</Link>
                      </li>
                      <li>
                        <Link to="#">Student App</Link>
                      </li>
                      <li>
                        <Link to="#">Online Payment</Link>
                      </li>

                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <a href="/Getintouch">Become our partner</a>
              </li>
              <li>
                <Link to="/Carrer" onClick={toggleMenu}>Careers</Link>
              </li>
              <li>
                <Link to="/Contactus" onClick={toggleMenu}>Contact Us</Link>
              </li>

              <li>
                <Link to="javascript:void(0)">
                  <label htmlFor="droplist6" className="toggle d-flex justify-content-between">
                    Register
                  </label>
                  <CaretDownOutlined />
                </Link>
                <input type="checkbox" id="droplist6" />
                {/* =============FirstDropDown================== */}
                <ul>
                  <li>
                    <Link to="Register" className="d-flex gap-2" onClick={toggleMenu}>
                      <span> <IoMdLogIn /> student register</span>
                      <input type="checkbox" id="droplist2" />
                    </Link>
                    {/* =========Sub Drop Menu========== */}
                  </li>
                  <li>
                    <Link to="/Scholrigster" className="d-flex gap-2" onClick={toggleMenu}>
                      <span>  <FaUser /> School Register</span>
                    </Link>
                  </li>


                </ul>
              </li>
           

              <li>
                <a href="https://merividhya.in/Admin/school">Login</a>
              </li>



            </ul>



            {/*  <div className="d-flex align-items-center mr-2">
        <div className="loginandmenu ">

          {isLoggedIn ? (
            <button onClick={logout}>
              <i className="bi bi-box-arrow-right"></i> Logout
            </button>
          ) : ( 
          <div className="d-flex align-items-center gap-2">
            <div className=" login mr-5">
             
            <a href="https://merividhya.inAdmin/school" className='menu-link text-dark d-flex align-items-center'> <IoMdLogIn /> <span>Login</span></a>
            </div>
            <div className="d-flex align-items-center login">
              
              <Link to="/Register" className='menu-link text-dark d-flex align-items-center'><FaUser /><span>Register</span></Link>
            </div>
           
            
            

          </div>


        </div>

      </div>*/}
          </section >
        </nav>
      </header >
    </>

  )
}
