import { Container, Row, Col } from 'react-bootstrap'
import logofooter from '../../assets/image/Logo.png'
import play from '../../assets/image/logoplaystrore.webp'
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter, FaInstagram, FaGooglePlusG, FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import inner_b3 from '../../assets/image/inner_b3.jpg'
import playy from '../../assets/image/pl.png'
import { IoLogoGooglePlaystore } from "react-icons/io5";


import React, { useState, useEffect } from 'react'
import axios from "axios";


import { Link } from 'react-router-dom'
export default function Footer() {


  const [contactData, setContactData] = useState(null);
  const [email, setEmail] = useState('');
  const [email2, setEmail2] = useState('');
  const [mobileno, setmobileno] = useState('');
  const [mobileno2, setmobileno2] = useState('');
  const [address, setaddress] = useState('');
  const [url, seturl] = useState('');
  const [school_logo, setschool_logo] = useState('');
  // alert(school_logo)
  const [facebook_link, setfacebook_link] = useState('');
  const [twitter_link, settwitter_link] = useState('');
  const [instagram_link, setinstagram_link] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const onSubmit = async () => {
    try {
      let url = `https://merividhya.in/Admin/api/contact-us-details-front`;
      const response = await axios.post(url);
      const responseData = response.data;
      const SuperAdminLogo = response.data.SuperAdminLogo;
      seturl(SuperAdminLogo)

      if (responseData && responseData.data && responseData.data.length > 0) {
        const data = responseData.data[0];
        setEmail(data.email);
        setEmail2(data.email2);
        setaddress(data.address);
        setschool_logo(data.site_logo);
        setmobileno(data.mobileno);
        setmobileno2(data.mobileno2);
        setfacebook_link(data.facebook_link);
        settwitter_link(data.twitter_link);
        setinstagram_link(data.instagram_link);
        setContactData(responseData);
      }
      setContactData(responseData);
    } catch (error) {
      setError('There was a problem with the fetch operation.');
      console.error('There was a problem with the fetch operation:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }















  return (
    <div id="Footer">
      <footer className="footer-bg footer-p pt-60" >
        <div className="footer-top">
          <Container>
            <Row className="justify-content-between">
              <Col xl={3} lg={3} sm={6}>
                <div className="footer-widget mb-30">
                  <div className="logo mt-15 mb-15" style={{borderRadius:"50%"}}>
                 
                    {school_logo
                      ? <img src={`${url}/${school_logo}`} className="img-fluid" alt="Empower" style={{borderRadius:"50%"}}/>
                      : <img src={inner_b3} className="img-fluid" alt="Fallback" />
                    }
                  </div>

                  <a href="https://play.google.com/store/apps/details?id=com.meri.vidhya&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">


                  <div className="mt-15 mb-15">
                    <img src={playy} className="img-fluid" alt="Fallback" width={'100px'} />
                  </div>
                  </a>
                  <div className="footer-social">



                    {/* <Link to="/facebook_link">
                      <FaFacebookF />
                    </Link> */}


                    <a href={`https://www.facebook.com/${facebook_link}`} target="_blank" rel="noopener noreferrer">
                      {/* {facebook_link} */}                      <FaFacebookF />

                    </a>

                    <a href={`https://x.com/${twitter_link}`} target="_blank" rel="noopener noreferrer">
                      {/* {facebook_link} */}                                          <FaTwitter />


                    </a>

                    <a href={`https://www.instagram.com/${instagram_link}`} target="_blank" rel="noopener noreferrer">
                      {/* {facebook_link} */}                                         <FaInstagram />


                    </a>

                    {/* <Link to="/instagram_link">
                      <FaInstagram />
                    </Link> */}
                    {/* <Link to="#">
                      <FaGooglePlusG />
                    </Link> */}
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} sm={6}>
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h5>Menu</h5>
                  </div>
                  <div className="footer-link">
                    <ul>
                      {/* <li>
                        <Link to="#">Partners</Link>
                      </li> */}
                      <li>
                        <Link to="/Aboutus">About Us</Link>
                      </li>
                      <li>
                        <Link to="/Privacy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/PaymentPolicy">Payment Policy</Link>
                      </li>

                      <li>
                        <Link to="/">Home</Link>
                      </li>


                    </ul>
                  </div>
                </div>
              </Col>
              <Col xl={2} lg={3} sm={6}>
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h5>Support</h5>
                  </div>
                  <div className="footer-link">
                    <ul>


                      <li>
                        <Link to="/RefundPolicy">Refund Policy</Link>
                      </li>
                      {/* <li>
                        <Link to="#">Reviews</Link>
                      </li> */}
                      <li>
                        <Link to="/TermsConditions">Terms &amp; Conditions</Link>
                      </li>

                      <li>
                        <Link to="/EndUserLicenseAgreement">End-User License Agreement (EULA)</Link>
                      </li>
                      {/* <li>
                        <Link to="#">About Us</Link>
                      </li> */}
                      {/* <li>
                        <Link to="#">Services</Link>
                      </li> */}
                      {/* <li>
                        <Link to="#">Project</Link>
                      </li>
                      <li>
                        <Link to="#">Our Team</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xl={3} lg={3} sm={6}>
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h5>Contact Us</h5>
                  </div>
                  <div className="footer-link">
                    <div className="f-contact">
                      <ul>
                        <li>
                          <div className="iconfooter">
                            <FaPhoneAlt />
                          </div>
                          <span>{mobileno}
                            <br />
                            {mobileno2}
                          </span>
                        </li>
                        <li>
                          <div className="iconfooter">
                            <IoMdMail />
                          </div>
                          <span>
                            <Link to="mailto:info@example.com">{email}</Link>
                            <br />
                            <Link to="mailto:sale@example.com">{email2}</Link>
                          </span>
                        </li>
                        <li>
                          <div className="iconfooter">
                            <MdLocationOn />
                          </div>
                          <span>
                            {address}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="copyright-wrap text-center">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="copyright-text">
                  <p>© 2024-2025 All Right Reserved </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>

    </div>
  )
}
