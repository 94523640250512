import { Link } from 'react-router-dom';
import aboutusimage from '../../assets/image/aboutusimage.png';
import img10 from '../../assets/image/img10.jpg';
import img8 from '../../assets/image/img8.jpg';
import img12 from '../../assets/image/img12.jpg';
import img11 from '../../assets/image/img11.jpg';
import shape1 from '../../assets/image/shape-1.png';
import shape2 from '../../assets/image/shape-2.png';
import shape3 from '../../assets/image/shape-3.png';
import shape4 from '../../assets/image/shape-4.png';
import { Container, Row, Col } from 'react-bootstrap';
import image from '../../assets/image/searchimage.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import imageteacher from '../../assets/image/imageteacher.png';

export default function AboutusHome() {
    const [contactData, setContactData] = useState(null);
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const onSubmit = async () => {
        try {
            let url = `https://merividhya.in/Admin/api/get-about-us-data`;
            const response = await axios.post(url);
            const responseData = response.data;

            console.log("API Response:", responseData);

            if (responseData && responseData.data) {
                const title = responseData.data.title;
                let description = responseData.data.description;
                setDescription(description);

                console.log("Original description:", description);

                const unwantedText = "It Uses A Dictionary Of Over 200 Latin Words, Combined With A Handful Of Model Sentence Structures, To Generate Lorem Ipsum Which Looks The Generated Lorem Ipsum Is Therefore Always Free From Repetition";
                description = description.replace(`<p>${unwantedText}</p>`, '');

                console.log("Modified description:", description);

                setTitle(title);
            } else {
                setError('No data found');
            }

            setContactData(responseData);
        } catch (error) {
            setError('There was a problem with the fetch operation.');
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setLoading(false);
        }
    };

    const [descriptionvission, setDescriptionvission] = useState('');
    const [titlevission, settitlevission] = useState('');
    const [imageVission, setimageVission] = useState('');
    const [urlVission, setImageUrlVission] = useState('');

    const ourVission = async () => {
        try {
            let url = `https://merividhya.in/Admin/api/super-admin-our-vission`;

            const response = await axios.post(url);
            const urlll = response.data.url; // Base URL for images
            setImageUrlVission(urlll)
            console.warn(response);

            const responseData = response.data;

            if (responseData && responseData.data && responseData.data.length > 0) {
                const description = responseData.data[0].description;
                const titlevission = responseData.data[0].title;

                const setimageee = responseData.data[0].image; // Assuming there's only one item in the array


                setimageVission(setimageee)
                setDescriptionvission(description);
                settitlevission(titlevission);

                console.log("Description:", description);
            } else {
                setError('No data found');
            }

        } catch (error) {
            setError('There was a problem with the fetch operation.');
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setLoading(false);
        }
    };

    const [descriptionempower, setDescriptionempower] = useState('');
    const [titleempower, settitleempower] = useState('');

    const empower = async () => {
        try {
            let url = `https://merividhya.in/Admin/api/super-admin-empower-students`;

            const response = await axios.post(url);
            console.warn(response);

            const responseData = response.data;

            if (responseData && responseData.data && responseData.data.length > 0) {
                const description = responseData.data[0].description;
                setDescriptionempower(description);
                settitleempower(titlevission);

                console.log("Description:", description);
            } else {
                setError('No data found');
            }

        } catch (error) {
            setError('There was a problem with the fetch operation.');
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setLoading(false);
        }
    };

    const [descriptionempowermission, setDescriptionempowermission] = useState('');
    const [titleempowermission, settitleempowermission] = useState('');
    const [image, setimage] = useState('');
    const [url, setImageUrl] = useState('');

    const mission = async () => {
        try {
            let url = `https://merividhya.in/Admin/api/super-admin-our-mission`;

            const response = await axios.post(url);
            console.warn(response);
            const urlll = response.data.url; // Base URL for images
            setImageUrl(urlll);
            // alert(urlll)

            const responseData = response.data;

            if (responseData && responseData.data && responseData.data.length > 0) {
                const description = responseData.data[0].description;
                const titlevission = responseData.data[0].title;
                const setimageee = responseData.data[0].image; // Assuming there's only one item in the array


                setimage(setimageee)

                setDescriptionempowermission(description);
                settitleempowermission(titlevission);

                console.log("Description:", description);
            } else {
                setError('No data found');
            }

        } catch (error) {
            setError('There was a problem with the fetch operation.');
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        onSubmit();
        ourVission();
        empower();
        mission();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="AboutusHome section-top-bottom" id="AboutusHome">
            <div className="bg-color-custum">
                <Container>
                    <Row className="">
                        <Col md={6} className="wow fadeInUp" data-wow-delay="0.1s">
                            <div className="position-relative h-100">
                                <img className="img-fluid" src={aboutusimage} alt="imageabout" />
                            </div>
                        </Col>
                        <Col md={6} className="wow fadeInUp abouthome" data-wow-delay="0.3s">
                            <h6 className="section-title bg-white text-start pe-3">{title}</h6>
                            <h1 className="mb-4">Welcome to Meri Vidhya</h1>
                            {description ? (
                                <div dangerouslySetInnerHTML={{ __html: description }} />
                            ) : (
                                <div>No description available</div>
                            )}
                        </Col>
                    </Row>
                    <section className="our-mission-area section--padding bg-gray position-relative" id='AboutusHome '>
                        <Container>
                            <img className="shap_1" src={shape1} alt="shape" />
                            <img className="shap_2" src={shape2} alt="shape" />
                            <img className="shap_3" src={shape3} alt="shape" />
                            <img className="shap_4" src={shape4} alt="shape" />
                            <Row>
                                <Col lg={6}>
                                    <div>
                                        <div className="abt-title">
                                            <h2>{titleempowermission}</h2>
                                        </div>
                                        <div className="about-desc">
                                                <div dangerouslySetInnerHTML={{ __html: descriptionempowermission }} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="abt-title position-relative">
                                            <h2>{titlevission}</h2>
                                        </div>
                                        <div className="about-desc">
                                            <div dangerouslySetInnerHTML={{ __html: descriptionvission }} />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <Row className="pb-5">
                                        <Col lg={6} className="responsive-column-half">
                                            <div className="img-box">
                                                {image
                                                    ? <img src={`${url}/${image}`} className="img-fluid img-fluid lazy rounded-rounded" alt="Empower" />
                                                    : <img src={imageteacher} className="img-fluid img-fluid lazy rounded-rounded" alt="Fallback" />
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={6} className="responsive-column-half  my-3">
                                            <div className="img-box ">
                                         
                                            </div>
                                        </Col>
                                        <Col lg={6} ></Col>
                                        <Col lg={6} className="responsive-column-half d-flex justify-content-end mt-2">
                                            <div className="img-box">
                                            {image
                                                    ? <img src={`${urlVission}/${imageVission}`} className="img-fluid img-fluid lazy rounded-rounded" alt="Empower" />
                                                    : <img src={imageteacher} className="img-fluid img-fluid lazy rounded-rounded" alt="Fallback" />
                                                }
                                            </div>
                                      
                                        </Col>
                                      
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Container>
            </div>
        </div>
    );
}
