import React, { useState ,useEffect } from 'react';
import axios from 'axios';
import getintouch from '../../assets/image/Getintouch-amico.png';
import { Container, Row, Col, Form, Alert } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function Getintouch() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    massage: '', // Updated field name
  });
  // console.warn();


  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    // Ensure the "massage" field is not empty
    if (!formData.massage.trim()) {
      setError("The massage field is required.");
      return;
    }

    axios.post('https://merividhya.in/Admin/api/super-admin-school-become-our-partner', formData)
      .then((response) => {
        setSuccess(true);
        setFormData({
          name: '',
          email: '',
          phone: '',
          massage: '',
        });

        const MySwal = withReactContent(Swal);

        if (response.data.status_code === 1) {
          MySwal.fire({
            title: response.data.message,
            icon: 'success',
            timer: 2500,
          }).then((result) => {
            window.location.href = '/';
          });
        } else {
          MySwal.fire({
            title: response.data.message,
            timer: 2000,
            icon: 'error',
          });
        }
      })
      .catch((error) => {
        setError(error.response?.data?.message || 'Something went wrong. Please try again.');
      });
  };





  const [descriptionempowermission, setDescriptionempowermission] = useState('');
  const [titleempowermission, settitleempowermission] = useState('');
  //  alert(titleempowermission)


  const mission = async () => {
      try {
          let url = `https://merividhya.in/Admin/api/super-admin-our-mission`;
  
          const response = await axios.post(url);
          console.warn(response);
  
          // Accessing the response data
          const responseData = response.data.BecomeOurPartner;
          settitleempowermission(responseData);
  
          // Check if the data and data array exist
          if (responseData && responseData.data && responseData.data.length > 0) {
              const description = responseData.data[0].description; // Assuming there's only one item in the array
              const titlevission = responseData.data.BecomeOurPartner; // Assuming there's only one item in the array
              //    alert(description)
              // setDescriptionempowermission(description);
              // settitleempowermission(titlevission);
  
              // Log the description
          } else {
          }
  
      } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
      } finally {
      }
  };


  
  





  useEffect(() => {
     
      mission();
          

  }, []);

  return (
    <div>
      <section
        id="contact"
        className="contact-area contact-bg pt-50 pb-100 p-relative fix padding-top-bottom"
      >
        <Container>
          <Row>
            <Col lg={6}>
              <div className="contact-img2">
                <img src={getintouch} alt="test" className='img-fluid' />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-touch">
                <div className="section-title mb-40">
                  <h2>Become Our Partners</h2>
                  <p>{titleempowermission}
                  </p>
                </div>
                {error && <Alert variant="danger">{error}</Alert>}
                {/* {success && <Alert variant="success">Your message has been sent successfully!</Alert>} */}
                <Form onSubmit={handleSubmit} className="contact-form">
                  <Row>
                    <Col lg={12}>
                      <div className="contact-field p-relative c-name mb-20">
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="contact-field p-relative c-email mb-20">
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="contact-field p-relative c-subject mb-20">
                        <Form.Control
                          type="text"
                          placeholder="Phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="contact-field p-relative c-message mb-45">
                        <textarea
                          name="massage"
                          id="massage"
                          cols={10}
                          rows={10}
                          placeholder="Write comments"
                          value={formData.massage}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <button className="btn" type="submit">Send Message</button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
