// import React from 'react'
import imageteacher from '../../assets/image/easy-m-bg.png'
import mobile2 from '../../assets/image/mobile2.png'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import appleicon from '../../assets/image/apple-icon.png'
import android from '../../assets/image/android.png'
import { BiLogoPlayStore } from "react-icons/bi";


import React, { useState, useEffect } from 'react';
import axios from 'axios';


export default function Downlaodapplication() {



  const [descriptionempower, setDescriptionempower] = useState('');
  const [titleempower, settitleempower] = useState('');
  const [image, setimage] = useState('');
  const [url, setImageUrl] = useState('');


  const removeHtmlTags = (text) => {
    return text.replace(/<\/?p>/g, ''); // Remove <p> and </p> tags
  };

  const empower = async () => {
    try {
      let url = `https://merividhya.in/Admin/api/super-admin-easyand_perfect`;

      const response = await axios.post(url);
      // console.warn(response);
      // alert(response)

      // Accessing the response data
      const responseData = response.data;
      const urlll = responseData.url; // Base URL for images
      setImageUrl(urlll)
      // alert(urlll);

      // Check if the data and data array exist
      if (responseData && responseData.data && responseData.data.length > 0) {
        const description = responseData.data[0].description; // Assuming there's only one item in the array
        const descriptiontitle = responseData.data[0].title; // Assuming there's only one item in the array
        const setimageee = responseData.data[0].image; // Assuming there's only one item in the array


        // Clean the description from <p> tags
        const cleanedDescription = removeHtmlTags(description);
        setimage(setimageee)
        setDescriptionempower(cleanedDescription);
        settitleempower(descriptiontitle);

        // Log the description
        console.log("Description:", cleanedDescription);
      } else {
        // Handle case where there's no data
      }

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  useEffect(() => {
    empower();
  }, []);
  return (
    <section
      className="choose-area pt-100 pb-60 position-relative bg-download"
      id="downloadsection"
    >
      <div className="chosse-img"
      />
      <div className="chosse-img2">
        {image
          ? <img src={`${url}/${image}`} className="img-fluid" alt="Empower" />
          : <img src={imageteacher} className="img-fluid" alt="Fallback" />
        }
      </div>
      <Container>
        <Row>
          <Col xl={5}></Col>
          <Col xl={7}>
            <div className="choose-wrap">
              <div className="section-title w-title left-align mb-25">
                <h2>{titleempower}</h2>
              </div>
              <div className="choose-content">
                <p>
                  {/* {descriptionempower} */}
                  <p dangerouslySetInnerHTML={{ __html: descriptionempower }} />

                </p>
                <p>

                </p>
                <div className="choose-btn mt-20">
                  <div className="btn_application">
                    <a href="https://play.google.com/store/apps/details?id=com.meri.vidhya&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                      <span className="icon" style={{ fontSize: "35px" }}>
                        <BiLogoPlayStore />
                      </span>
                      <span className="text">
                        Available on <strong>Play STORE</strong>
                      </span>
                    </a>


                  </div>
                  <div className="btn_application">

                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

  )
}
