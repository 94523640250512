import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { FaGooglePlusG } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import loginimage from '../../assets/image/login.png'
import herocircle from '../../assets/image/hero-1-circle.png'
import heroshape from '../../assets/image/hero-1-shape.png'
import shape from '../../assets/image/shape.png'
import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaLock } from "react-icons/fa";
import { MdOutlineLocationCity } from "react-icons/md";
import { FaBirthdayCake } from "react-icons/fa";
import { GrCertificate } from "react-icons/gr";
import { HiPhoto } from "react-icons/hi2";
import { VscReferences } from "react-icons/vsc";
import { MdWork } from "react-icons/md";
import { BiSolidSchool } from "react-icons/bi";
import { SiGoogleclassroom } from "react-icons/si";
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const CustomInput = ({ value, onClick }) => (
  <input
    type="text"
    value={value}
    onClick={onClick}
    placeholder="dd/mm/yyyy"
    readOnly
    className="form-control w-100 d-block"
  />
);


export default function Register() {
  const [schools, setSchools] = useState([]);
  const [Class, setClass] = useState([]);

  useEffect(() => {
    fetchSchools();
  }, [])



  const fetchSchools = async () => {

    let url = 'https://merividhya.in/Admin/api/school-list-front';
    var config = {
      method: "POST",
      url: url,
    };
    try {
      const response = await axios(config);
      const responseData = response.data && response.data.data;


      setSchools(responseData);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [selectedValue, setSelectedValue] = useState();
  const [selectedclass, setselectedclass] = useState();
  console.warn(selectedclass)

  const handleSelectclass = (event) => {
    setselectedclass(event.target.value);
  };

  const handleSelectChange = async (event) => {
    const selectedId = event.target.value;
    console.log(selectedId);
    setSelectedValue(selectedId);


    let url = 'https://merividhya.in/Admin/api/class-lists-accordig-school';
    const formData = new FormData();
    formData.append("school_id", selectedId);

    var config = {
      method: "POST",
      url: url,
      data: formData,
    };


    try {
      const response = await axios(config);
      const responseData = response.data && response.data.data;
      console.warn(responseData)
      setClass(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };







  const [name, setname] = useState("")
  const [father_name, Setfather_name] = useState("")
  const [mother_name, setmother_name] = useState("")
  const [mother_number, setmother_number] = useState("")
  const [parents_email, setparents_email] = useState("")
  const [guardian_name, setguardian_name] = useState("")
  const [guardian_number, setguardian_number] = useState("")
  const [student_addresh, setstudent_addresh] = useState("")
  const [student_email, setstudent_email] = useState("")
  const [student_mobile, setstudent_mobile] = useState("")
  const [student_birth_date, setstudent_birth_date] = useState("")
  const [student_aadhar_card_number, setstudent_aadhar_card_number] = useState("")
  const [student_religion, setstudent_religion] = useState("")
  const [category, setcategory] = useState("")
  const [gender, setgender] = useState("")
  const [father_occpation, setfather_occpation] = useState("")
  const [mother_occpation, setmother_occpation] = useState("")
  const [student_bitrh_certificate, setstudent_bitrh_certificate] = useState("")
  const [student_image, setstudent_image] = useState("")
  const [father_number, setfather_number] = useState("")
  const [selectedReligion, setSelectedReligion] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);





  const handleFrontPicChange = (event) => {
    setstudent_bitrh_certificate(event.target.files[0]);
  };
  const handlesetstudent_image = (event) => {
    setstudent_image(event.target.files[0]);
  };

  const onSubmit = async () => {






    if (name == "" || mother_name == "" || mother_number == "" || parentsEmail == "" || guardian_name == "" || guardian_number == "" || student_addresh
      == "" || student_email == "" ||
      student_mobile == "" || student_birth_date == "" || student_aadhar_card_number == "" ||
      father_occpation == "" || mother_occpation == "" || schools == "" || Class == "" || selectedclass == "" || father_name == "") {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: 'Please fill out the required fields',

        timer: 1000,
        icon: 'error'
      });
      return;
    }

    if (mother_number.length !== 10
      || father_number.length !== 10 || guardian_number.length !== 10 || student_mobile.length !== 10) {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: 'Phone number must be 10 digits',
        timer: 2000,
        icon: 'error'
      });
      return;
    }

    if (!selectedclass) {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: 'school input required',
        timer: 2000,
        icon: 'error'
      });
      return;
    }

    let url = 'https://merividhya.in/Admin/api/submit-student-request-to-school';
    const formData = new FormData();
    formData.append("school_id", selectedValue);
    formData.append("class_id", selectedclass);

    formData.append("name", name);
    formData.append("father_name", father_name);
    formData.append("mother_number", mother_number);
    formData.append("parents_email", parentsEmail);
    formData.append("guardian_name", guardian_name);
    formData.append("guardian_number", guardian_number);
    formData.append("student_addresh", student_addresh);
    formData.append("student_email", student_email);
    formData.append("student_mobile", student_mobile);
    formData.append("student_birth_date", student_birth_date);
    formData.append("student_aadhar_card_number", student_aadhar_card_number);
    formData.append("student_religion", selectedReligion || "Hindu");
    formData.append("category", selectedCategory || "OBC");
    formData.append("gender", selectedGender || "Boy ");
    formData.append("father_occpation", father_occpation);
    formData.append("mother_occpation", mother_occpation);
    formData.append("student_bitrh_certificate", student_bitrh_certificate);
    formData.append("student_image", student_image);
    formData.append("father_number", father_number);
    formData.append("mother_name", mother_name);

    var config = {
      method: "POST",
      url: url,
      data: formData,
    };


    try {
      const response = await axios(config);
      console.warn(response)

      const MySwal = withReactContent(Swal);

      if (response.data.status_code === 1) {

        console.warn(response)
        MySwal.fire({
          title: response.data.message,
          icon: 'success',
          timer: 2500
        }).then((result) => {
          window.location.href = '/Register';


        });
      } else {
        MySwal.fire({
          title: response.data.message,
          timer: 2000,
          icon: 'error',

        });
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const [parentsEmail, setParentsEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setParentsEmail(email);

    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if email matches the pattern
    if (!emailPattern.test(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const [emailErrorr, setEmailErrorr] = useState('');

  const handleEmailChangestunt = (e) => {
    const email = e.target.value;
    setstudent_email(email);

    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if email matches the pattern
    if (!emailPattern.test(email)) {
      setEmailErrorr('Please enter a valid email address');
    } else {
      setEmailErrorr('');
    }
  };








  const [facebook_link, setfacebook_link] = useState('');
  const [twitter_link, settwitter_link] = useState('');
  const [instagram_link, setinstagram_link] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [email2, setEmail2] = useState('');


  const onSubmitt = async () => {
    try {
      let url = `https://merividhya.in/Admin/api/contact-us-details-front`;
      const response = await axios.post(url);
      const responseData = response.data;


      if (responseData && responseData.data && responseData.data.length > 0) {
        const data = responseData.data[0];
        setEmail(data.email);
        setEmail2(data.email2);
        setfacebook_link(data.facebook_link);
        settwitter_link(data.twitter_link);
        setinstagram_link(data.instagram_link);
      }
      // setContactData(responseData);
    } catch (error) {
      setError('There was a problem with the fetch operation.');
      console.error('There was a problem with the fetch operation:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onSubmitt();
  }, []);






  return (
    <main id="Login">
      <div className="banner-part sub-page-banner">
        <div className="sub-banner">
          <div className="container">
            <h1>Register</h1>
            <ul className="d-flex justify-content-center list-unstyled">
              <li>
                Explore, enhance and empower your school with MERIVIDHYA
              </li>
            </ul>
          </div>
        </div>
        {/* <img src={subbanner} alt="sub" /> */}
      </div>
      <section className="contact-area section--padding position-relative">
        <Container>
          <Row className="align-items-center">

            <Col md={12}>
              <Card className="card-item">
                <Card>
                  <Card.Title className="text-center fs-24 lh-35 pb-4">
                    Welcome to Student Register Dashboard                  </Card.Title>
                  <div className="section-block" />
                  <Form method="post" className="pt-4">
                    <div className="d-flex flex-wrap justify-content-between align-items-center pb-4">
                      <a href="https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fwww.google.com%2Fsearch%3Fq%3Dgmail%26rlz%3D1C1UEAD_en-GBIN1102IN1102%26oq%3Dgm%26gs_lcrp%3DEgZjaHJvbWUqBwgBEAAYjwIyBggAEEUYOTIHCAEQABiPAjIHCAIQABiPAjIHCAMQABiPAjIGCAQQRRg90gEINjA5OGowajGoAgCwAgA%26sourceid%3Dchrome%26ie%3DUTF-8&ec=futura_gmv_dt_so_72586115_e&hl=en&ifkv=Ab5oB3r88f28QVBhgpMmRGTkxw_O2ivSdBE2I1_2UVsDdo3Iaj1N4wIBrJwoR-OzpBTJtr2prFI76g&passive=true&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S1462971720%3A1723453223314282&ddm=0" className="w-100" style={{display:'contents'}}>
                        <button type="button" className="btn theme-btn flex-grow-1 mx-2 mb-2">
                          <FaGooglePlusG className='mr-2' />
                          Google
                        </button>
                      </a>

                      <a href={`https://www.facebook.com/${facebook_link}`} target="_blank" rel="noopener noreferrer" className="w-100" style={{display:'contents'}}>
                        <button type="button" className="btn theme-btn flex-grow-1 mx-2 mb-2">
                          <FaTwitter className="mr-2" /> Facebook
                        </button>
                      </a>

                      <a href={`https://x.com/${twitter_link}`} target="_blank" rel="noopener noreferrer" className="w-100" style={{display:'contents'}}>
                        <button type="button" className="btn theme-btn flex-grow-1 mx-2 mb-2">
                          <FaTwitter className="mr-2" /> Twitter
                        </button>
                      </a>

                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="icon-element d-flex justify-content-center  icon-element-md fs-25 shadow-sm">
                        OR
                      </div>
                    </div>

                    <Row className="mb-2">
                      <Col md={6} xs={6}>

                        <select value={selectedValue} onChange={handleSelectChange} className='select_market form-control'>
                          <option className='font-size-5'>Select School</option>
                          {schools.map((item, index) => (
                            <option key={item.school_id} value={item.school_id}>
                              {item.school_name}
                            </option>
                          ))}
                        </select>

                      </Col>
                      <Col md={6} xs={6}>

                        <select className='select_market form-control' value={selectedclass} onChange={handleSelectclass}>
                          <option lassName='font-size-5'>Select  Class</option>
                          {Class.map((item, index) => (
                            <option key={index.id} value={item.class_id}>
                              {item.class_name}
                            </option>
                          ))}
                        </select>
                      </Col>

                    </Row>
                    <hr />
                    <Row>
                      <Col xs={6}>

                        <div className="input-box">
                          <label className="label-text">Student Name </label>
                          <div className="form-group  position-relative">
                            <input
                              className="form-control form--control"
                              type="text"
                              name="name"
                              value={name}
                              onChange={(e) => setname(e.target.value)}
                              placeholder="  Student Name "
                            />

                            <FaUser className='input-icon' />
                          </div>
                        </div>
                      </Col>
                      <Col xs={6}>

                        <div className="input-box">
                          <label className="label-text">  Father's Name </label>
                          <div className="form-group  position-relative">
                            <input
                              className="form-control form--control"
                              type="text"
                              name="name"
                              placeholder=" Father's Name "
                              value={father_name}
                              onChange={(e) => Setfather_name(e.target.value)}
                            />
                            <FaUser className='input-icon' />

                          </div>
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="input-box">
                          <label className="label-text">Mother's Name</label>
                          <div className="form-group  position-relative">
                            <input
                              className="form-control form--control"
                              type="text"
                              name="name"
                              placeholder="Mother's Name"
                              value={mother_name}
                              onChange={(e) => setmother_name(e.target.value)}
                            />
                            <FaUser className='input-icon' />
                          </div>
                        </div>
                      </Col>



                      <Col xs={6}>
                        <div className="input-box">
                          <label className="label-text"> Mother's Mobile No</label>
                          <div className="form-group  position-relative">
                            <input
                              className="form-control form--control"
                              type="number"
                              name="name"
                              placeholder="Number"
                              value={mother_number}
                              onChange={(e) => setmother_number(e.target.value.slice(0, 10))}
                            />
                            <FaPhone className='input-icon' />
                          </div>
                        </div>
                      </Col>



                    </Row>

                    <Row>



                      <Col>

                        <div className="input-box">
                          <label className="label-text"> Father's Email</label>
                          <div className="form-group position-relative">
                            <input
                              className="form-control form--control"
                              type="emal"  // Use type="email" for email input
                              name="nam"
                              placeholder="Father's Email"
                              value={parentsEmail}
                              onChange={handleEmailChange}
                            />
                            <IoMdMail className='input-icon' />
                          </div>
                          {emailError && <div className="error-message" style={{ color: "red" }}>{emailError}</div>}
                        </div>
                      </Col>

                      <Col>
                        <div className="input-box">
                          <label className="label-text"> Guardian Name</label>
                          <div className="form-group  position-relative">
                            <input
                              className="form-control form--control"
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={guardian_name}
                              onChange={(e) => setguardian_name(e.target.value)}
                            />
                            <FaUser className='input-icon' />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>


                      <Col md={4}>
                        <div className="input-box">
                          <label className="label-text"> Guardian Number</label>
                          <div className="form-group  position-relative">
                            <input
                              className="form-control form--control"
                              type="number"
                              name="name"
                              placeholder="Number"
                              value={guardian_number}
                              onChange={(e) => setguardian_number(e.target.value.slice(0, 10))}
                            />
                            <FaPhone className='input-icon' />
                          </div>
                        </div>
                      </Col>


                      <Col md={4}>

                        <div className="input-box">
                          <label className="label-text">Mother's Occupation</label>
                          <div className="form-group  position-relative">
                            <input
                              className="form-control form--control"
                              type="text"
                              name="name"
                              placeholder="Mother's Occupation"
                              value={mother_occpation}
                              onChange={(e) => setmother_occpation(e.target.value)}
                            />
                            <MdWork className='input-icon' />
                          </div>
                        </div>
                      </Col>



                      <Col md={4}>


                        <div className="input-box">
                          <label className="label-text">Father's Occupation</label>
                          <div className="form-group  position-relative">
                            <input
                              className="form-control form--control"
                              type="text "
                              name="name"
                              placeholder="Father's Occupation"
                              value={father_occpation}
                              onChange={(e) => setfather_occpation(e.target.value)}
                            />
                            <MdWork className='input-icon' />
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>





                        <div className="input-box">
                          <label className="label-text">Student Email</label>
                          <div className="form-group  position-relative">
                            <input
                              className="form-control form--control"
                              type="mail"
                              name="name"
                              placeholder="Email "
                              value={student_email}
                              onChange={handleEmailChangestunt}
                            // onChange={(e) => setstudent_email(e.target.value)}
                            />
                            <IoMdMail className='input-icon' />
                            {emailErrorr && <div className="error-message" style={{ color: "red" }}>{emailErrorr}</div>}

                          </div>
                        </div>
                      </Col>
                      <Col md={4}>

                        <div className="input-box">
                          <label className="label-text">Student Number </label>
                          <div className="form-group  position-relative">
                            <input
                              className="form-control form--control"
                              type="number"
                              name="name"
                              placeholder="Number . "
                              value={student_mobile}
                              onChange={(e) => setstudent_mobile(e.target.value.slice(0, 10))}
                            />
                            <FaPhone className='input-icon' />
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>

                        <div className="input-box">
                          <label className="label-text"> Father's Number</label>
                          <div className="form-group  position-relative">
                            <input
                              className="form-control form--control"
                              type="number"
                              name="name"
                              placeholder="Father's Number"
                              value={father_number}
                              onChange={(e) => setfather_number(e.target.value.slice(0, 10))}
                            />
                            <FaPhone className='input-icon' />
                          </div>
                        </div>
                      </Col>

                      <Col md={4}>

                        <div className="input-box">
                          <label className="label-text">Student Birth Certificate</label>
                          <div className="dropzone-wrapper">
                            <div className="dropzone-desc">
                              <i class="fa fa-upload" aria-hidden="true"></i>
                            </div>
                            <input type="file" name="backPic" id="backPic" className="form-control dropzone" onChange={handleFrontPicChange} />
                            {/* { <p> {student_bitrh_certificate.name}</p>} */}
                          </div>

                        </div>
                      </Col>
                      <Col md={4}>


                        <div className="input-box">
                          <label className="label-text">Student Photo</label>
                          <div className="dropzone-wrapper">
                            <div className="dropzone-desc">
                              <i class="fa fa-upload" aria-hidden="true"></i>
                            </div>
                            <input type="file" name="backPic" id="backPic" className="form-control dropzone" onChange={handlesetstudent_image} />
                          </div>
                        </div>
                      </Col>





                      <Col md={4}>

                        <div className="input-box">
                          <label className="label-text">Student Birth Date</label>
                          <div className="form-group  position-relative">
                            {/* <input
                              className="form-control form--control"

                              type="date"

                              id="dob"
                              placeholder="  Here "
                              value={student_birth_date}
                              onChange={(e) => setstudent_birth_date(e.target.value)}
                            /> */}


                            {/* <DatePicker
                              className="form-control"
                              selected={student_birth_date} // use selected instead of value
                              onChange={(date) => setstudent_birth_date(date)} // update state on date change
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/mm/yyyy"
                              customInput={<CustomInput />}
                            /> */}
                            <DatePicker
                              selected={student_birth_date}
                              onChange={(date) => setstudent_birth_date(date)}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/mm/yyyy"
                              customInput={<CustomInput />}
                              showYearDropdown
                              yearDropdownItemNumber={30}
                              scrollableYearDropdown
                            />

                            <FaBirthdayCake className='input-icon newcake' />
                          </div>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="input-box">
                          <label className="label-text">Address</label>
                          <div className="form-group  position-relative">
                            <textarea
                              className="form-control form--control"
                              type="text"
                              name="name"
                              placeholder=" Enter Here "
                              value={student_addresh}
                              onChange={(e) => setstudent_addresh(e.target.value)}
                            />
                            <MdOutlineLocationCity className='input-icon' />
                          </div>
                        </div>
                      </Col>



                      <Col md={4}>

                        <div className="input-box">
                          <label className="label-text">Student Aadhar Number</label>
                          <div className="form-group  position-relative">
                            <input
                              className="form-control form--control"
                              type="number"
                              name="name"
                              placeholder="Aadhaar Card Number"
                              value={student_aadhar_card_number}
                              onChange={(e) => setstudent_aadhar_card_number(e.target.value.slice(0, 12))}
                            />
                            <FaLock className='input-icon' />
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                      </Col>
                      <Col md={12}>
                        <div className="input-box">
                          <label className="label-text">Student Religion</label>
                          <div className="form-group position-relative" style={{ display: "flex", alignItems: "center" }}>
                            <div className='spce'>
                              <input
                                type="radio"
                                id="Hindu"
                                name="religion"
                                value="Hindu"
                                onChange={(e) => setSelectedReligion(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedReligion === null || selectedReligion === 'Hindu'}
                              />
                              <label htmlFor="Hindu">Hindu</label>
                            </div>
                            <div className='spce'>
                              <input
                                type="radio"
                                id="Christian"
                                name="religion"
                                value="Christian"
                                onChange={(e) => setSelectedReligion(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedReligion === 'Christian'}
                              />
                              <label htmlFor="Christian">Christian</label>
                            </div>
                            <div className='spce'>
                              <input
                                type="radio"
                                id="Muslim"
                                name="religion"
                                value="Muslim"
                                onChange={(e) => setSelectedReligion(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedReligion === 'Muslim'}
                              />
                              <label htmlFor="Muslim">Muslim</label>
                            </div>
                            <div className='spce'>
                              <input
                                type="radio"
                                id="Sikh"
                                name="religion"
                                value="Sikh"
                                onChange={(e) => setSelectedReligion(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedReligion === 'Sikh'}
                              />
                              <label htmlFor="Sikh">Sikh</label>
                            </div>
                          </div>
                        </div>

                      </Col>

                      <Col md={12}>
                        <div className="input-box">
                          <label className="label-text">Gender</label>
                          <div className="form-group position-relative" style={{ display: "flex", alignItems: "center" }}>
                            <div className='spce'>
                              <input
                                type="radio"
                                id="Boy "
                                name="gender"
                                value="Boy "
                                onChange={(e) => setSelectedGender(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedGender === null || selectedGender === 'Boy '}
                              />
                              <label htmlFor="Boy ">Boy </label>
                            </div>
                            <div className='spce'>
                              {/* <input
                                type="radio"
                                id="Girl"
                                name="gender"
                                value="Girl  "
                                onChange={(e) => setSelectedGender(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedGender === 'Girl'}
                              />
                              <label htmlFor="Girl">Girl</label> */}


                              <input
                                type="radio"
                                id="Girl"
                                name="gender"
                                value="Girl"
                                onChange={(e) => setSelectedGender(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedGender === 'Girl'}
                              />
                              <label htmlFor="Girl">Girl</label>
                            </div>
                            <div className='spce'>
                              <input
                                type="radio"
                                id="Other"
                                name="gender"
                                value="Other"
                                onChange={(e) => setSelectedGender(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedGender === 'Other'}
                              />
                              <label htmlFor="Other">Other</label>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="input-box">
                          <label className="label-text">Category</label>
                          <div className="form-group position-relative" style={{ display: "flex", alignItems: "center" }}>
                            <div className='spce'>
                              <input
                                type="radio"
                                id="OBC"
                                name="category"
                                value="OBC"
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedCategory === 'OBC' || selectedCategory === null}
                              />
                              <label htmlFor="OBC">OBC</label>
                            </div>

                            <div className='spce'>
                              <input
                                type="radio"
                                id=" General "
                                name="category"
                                value=" General "
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedCategory === ' General '}
                              />
                              <label htmlFor=" General "> General </label>
                            </div>


                            <div className='spce'>
                              <input
                                type="radio"
                                id="SC"
                                name="category"
                                value="SC"
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedCategory === 'SC'}
                              />
                              <label htmlFor="SC">SC</label>
                            </div>
                            <div className='spce'>
                              <input
                                type="radio"
                                id="ST"
                                name="category"
                                value="ST"
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedCategory === 'ST'}
                              />
                              <label htmlFor="ST">ST</label>
                            </div>
                            <div className='spce'>
                              <input
                                type="radio"
                                id="SBC"
                                name="category"
                                value="SBC"
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="form--control mx-1"
                                checked={selectedCategory === 'SBC'}
                              />
                              <label htmlFor="SBC">SBC</label>
                            </div>
                          </div>
                        </div>

                      </Col>

                      <Col md={12} className="d-flex justify-content-end">
                        <div className="btn-box">
                          <div className="d-flex align-items-center justify-content-between pb-4">
                            <div className="custom-control custom-checkbox fs-15">
                              {/* <input
                            type="checkbox"
                            className="custom-control-input"
                            id="rememberMeCheckbox"
                            required=""
                          /> */}
                              {/* <label
                            className="custom-control-label custom--control-label"
                            htmlFor="rememberMeCheckbox"
                          >
                            Remember Me
                          </label> */}
                            </div>

                          </div>
                          <button className="btn theme-btn d-flex justify-content-center w-100 align-items-center" type="button" onClick={onSubmit}>

                            Sign Up Account <FaArrowRightLong />

                          </button>

                        </div>
                      </Col>
                    </Row>

















                    {/* end input-box */}

                    {/* end input-box */}

                    {/* end btn-box */}
                  </Form>
                </Card>
                {/* end card-body */}
              </Card>
              {/* end card */}
            </Col>
            {/* end col-lg-7 */}

            <Col md={12} className="d-none">
              <div className="imagelogin">
                <img src={loginimage} />
                <div className="hero__shape">
                  <img src={herocircle} className='hero-1-circle' />
                  <img src={heroshape} className='hero-1-shape' />
                  <img src={shape} className='hero-2-shape' />
                </div>
              </div>
            </Col>
          </Row>
          {/* end row */}
        </Container>
        {/* end container */}
      </section>


    </main >
  )
}
