
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import inner_b3 from '../../assets/image/inner_b3.jpg'
import { Container, Row, Col } from 'react-bootstrap'
import admin from '../../assets/image/admin-img.png'
// import Logo from '../../assets/image/Logo.png'
import Logo from '../../assets/image/pl.png'
import { Link } from 'react-router-dom'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Clients = () => {
  const [clients, setClients] = useState([]);
  const [headerDescription, setHeaderDescription] = useState('');

  const [url, setImageUrl] = useState('');
  // alert(url)

  useEffect(() => {
    axios.get('https://merividhya.in/Admin/api/super-admin-our-clients')
      .then(response => {
        const urlll = response.data.url; // Base URL for images
        setImageUrl(urlll)
        if (response.data.status === 1) {
          setClients(response.data.data);
          setHeaderDescription(response.data.HeaderDescription.heading_desc);
          console.warn(response)
        } else {
          console.error('Failed to fetch data');
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  var autoplay  = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay:true,
    margin:"15px",
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: "10px",
        }
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: "5px",
        }
      },
    ]
  };
  
  return (
    <div className='padding-top-bottom'  id="blog">
    
      <Container>
        <Row className="justify-content-center">
          <Col xl={7} lg={10}>
            <div className="section-title text-center mb-50">
            <h2>Our Clients</h2>
      <p>{headerDescription}</p>

            </div>
          </Col>
        </Row>
        <Row>
        <Slider {...autoplay }>
            {clients.map(client => (
          <Col lg={4} md={12}>
              <>
                <div key={client.id}  className="single-post mb-30">
                  <div className="blog-thumb">
                    <Link to="void:javascript(0);">
                      {/* <img src={inner_b3} alt="img" /> */}
                      {client.school_logo 
                ? <img src={`${url}/${client.school_logo}`} className="img-fluid" alt="Empower" />
                : <img src={inner_b3} className="img-fluid" alt="Fallback" />
            }
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="b-meta mb-40">

                      <ul>
                        <li>
                          <Link to="#">{client.school_name}</Link>
                        </li>
                      </ul>
                    </div>

                    <h4>
                      {client.school_code}
                    </h4>
                    <div className="d-flex align-items-center justify-content-between admin">


                      <ul>
                       
                        <li className='d-flex gap-2'
                        >
                          <h6>{client.city} {client.state}</h6>   
                        </li>
                       
                      </ul>

                    </div>


                  </div>
                </div>
              </>
          </Col >
            ))}
    </Slider>

        </Row>
      </Container>
    </div>
  );
};

export default Clients;









// import React from 'react'
// import inner_b3 from '../../assets/image/inner_b3.jpg'
// import { Container, Row, Col } from 'react-bootstrap'
// import admin from '../../assets/image/admin-img.png'
// // import Logo from '../../assets/image/Logo.png'
// import Logo from '../../assets/image/pl.png'

// import { Link } from 'react-router-dom'
// export default function Blog() {
//   return (
//     <section
//       id="blog"
//       className="blog-area p-relative pt-70 padding-top-bottom"
//       style={{
//         backgroundImage: "url(img/shape/header-sape8.png)",
//         backgroundPosition: "right center",
//         backgroundSize: "auto",
//         backgroundRepeat: "no-repeat"
//       }}
//     >
//       <Container>
//         <Row className="justify-content-center">
//           <Col xl={7} lg={10}>
//             <div className="section-title text-center mb-50">
//               <h2>Our Clients </h2>
//               <p>
//                 Quisque posuere mollis ipsum et molestie. Fusce cursus, risus vel
//                 scelerisque porttitor, leo quam vulputate nibh, sit amet blandit
//                 erat magna.
//               </p>
//             </div>
//           </Col>
//         </Row>
//         <Row>
//           <Col lg={4} md={12}>
//             <div className="single-post mb-30">
//               <div className="blog-thumb">
//                 <Link to="blog-details.html">
//                   <img src={inner_b3} alt="img" />
//                 </Link>
//               </div>
//               <div className="blog-content">
//                 <div className="b-meta mb-40">

//                   <ul>
//                     <li>
//                       <Link to="#">School Name</Link>
//                     </li>
//                   </ul>
//                 </div>

//                 <h4>
//                   Meri Vidhaya Senior Secondry School
//                 </h4>
//                 <div className="d-flex align-items-center justify-content-between admin">


//                   <ul>
//                     <li>
//                       <div className="logodetails">
//                         <a href="https://play.google.com/store/games?device=windows" target="_blank">
//                           <img src={Logo} width="50" height="50" alt="Logo" />
//                         </a>
//                       </div>
//                     </li>
//                     <li>
//                       <h6>Dausa</h6> Rajasthan
//                     </li>
//                   </ul>

//                 </div>


//               </div>
//             </div>
//           </Col>
//           <Col lg={4} md={12}>
//             <div className="single-post mb-30">
//               <div className="blog-thumb">
//                 <Link to="blog-details.html">
//                   <img src={inner_b3} alt="img" />
//                 </Link>
//               </div>
//               <div className="blog-content">
//                 <div className="b-meta mb-40">

//                   <ul>
//                     <li>
//                       <Link to="#">School Name</Link>
//                     </li>
//                   </ul>
//                 </div>

//                 <h4>
//                   Meri Vidhaya Senior Secondry School
//                 </h4>
//                 <div className="d-flex align-items-center justify-content-between admin">


//                   <ul>
//                     <li>
//                       <div className="logodetails">
//                         <a href="https://play.google.com/store/games?device=windows" target="_blank">
//                           <img src={Logo} width="50" height="50" alt="Logo" />
//                         </a>
//                       </div>
//                     </li>
//                     <li>
//                       <h6>Dausa</h6> Rajasthan
//                     </li>
//                   </ul>

//                 </div>


//               </div>
//             </div>
//           </Col>
//           <Col lg={4} md={12}>
//             <div className="single-post mb-30">
//               <div className="blog-thumb">
//                 <Link to="blog-details.html">
//                   <img src={inner_b3} alt="img" />
//                 </Link>
//               </div>
//               <div className="blog-content">
//                 <div className="b-meta mb-40">

//                   <ul>
//                     <li>
//                       <Link to="#">School Name</Link>
//                     </li>
//                   </ul>
//                 </div>

//                 <h4>
//                   Meri Vidhaya Senior Secondry School
//                 </h4>
//                 <div className="d-flex align-items-center justify-content-between admin">


//                   <ul>
//                     <li>
//                       <div className="logodetails">
//                         <a href="https://play.google.com/store/games?device=windows" target="_blank">
//                           <img src={Logo} width="50" height="50" alt="Logo" />
//                         </a>
//                       </div>
//                     </li>
//                     <li>
//                       <h6>Dausa</h6> Rajasthan
//                     </li>
//                   </ul>

//                 </div>


//               </div>
//             </div>
//           </Col>

//         </Row>
//       </Container>
//     </section>

//   )
// }
