import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LuPhoneCall } from "react-icons/lu";
import { IoMdMail } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function Contactus() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });
    // console.warn(name)

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        if (name === "phone") {
            // Remove any non-numeric characters
            const numericValue = value.replace(/\D/g, '');
    
            // Limit the length to 10 digits
            if (numericValue.length <= 10) {
                setFormData({ ...formData, [name]: numericValue });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        // Validate form fields
        if (!formData.name || !formData.email || !formData.phone || !formData.subject || !formData.message) {
            const MySwal = withReactContent(Swal);

            MySwal.fire({
                title: 'All fields are required.',
                icon: 'error',
                timer: 2500,
            }).then(() => {
                // window.location.href = '/';
            });

            
            // setError('All fields are required.');
            return;
        }

        try {
            const response = await axios.post('https://merividhya.in/Admin/api/super-admin-contact-us', formData);
            const MySwal = withReactContent(Swal);

            if (response.data.status_code === 1) {
                MySwal.fire({
                    title: response.data.message,
                    icon: 'success',
                    timer: 2500,
                }).then(() => {
                    window.location.href = '/';
                });
            } else {
                MySwal.fire({
                    title: response.data.message,
                    timer: 2500,
                    icon: 'error',  
                }).then(() => {
                    window.location.href = '/';
                });
            }
        } catch (error) {
            setError(error.response?.data?.message || 'Something went wrong. Please try again.');
        }
    };

    return (
        <React.Fragment>
            <div className="contactus">
                <section className="contactus" id="contactus">
                    <div className="banner-part sub-page-banner">
                        <div className="sub-banner">
                            <div className="container">
                                <h1>Contact Us</h1>
                                <ul className="d-flex justify-content-center list-unstyled">
                                    {/* <li>Lorem ipsum dolor sit amet</li> */}
                                </ul>
                            </div>
                        </div>
                        {/* <img src={subbanner} alt="sub" /> */}
                    </div>
                    <div className="contact-one">
                        <Container className="wow fadeInUp animated" data-wow-delay="300ms" style={{ visibility: "visible", animationDelay: "300ms", animationName: "fadeInUp" }}>
                            <div className="section-title text-center">
                                <h5 className="section-title__tagline">
                                    Contact with Us
                                    <svg className="arrow-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 13">
                                        <g clipPath="url(#clip0_324_36194)">
                                            <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                                            <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                                            <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                                            <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                                        </g>
                                    </svg>
                                </h5>
                                <h2 className="section-title__title">Feel Free to Write Us Anytime</h2>
                            </div>
                            <div className="contact-one__form-box text-center">
                                <form onSubmit={handleSubmit} className="contact-one__form contact-form-validated" noValidate>
                                    <Row>
                                        <Col md={6}>
                                            <div className="contact-one__input-box">
                                                <input type="text" placeholder="Your Name" name="name" value={formData.name} onChange={handleChange} />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="contact-one__input-box">
                                                <input type="email" placeholder="Email Address" name="email" value={formData.email} onChange={handleChange} />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="contact-one__input-box">
                          <input type="text" placeholder="Phone" name="phone" value={formData.phone} onChange={handleChange} />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="contact-one__input-box">
                                                <input type="text" placeholder="Subject" name="subject" value={formData.subject} onChange={handleChange} />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="contact-one__input-box text-message-box">
                                                <textarea name="message" placeholder="Write a Message" value={formData.message} onChange={handleChange} />
                                            </div>
                                            <div className="contact-one__btn-box">
                                                <button type="submit" className="eduact-btn eduact-btn-second">
                                                    <span className="eduact-btn__curve" />
                                                    Send a Message
                                                    <i className="icon-arrow" />
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                                {error && <p style={{ color: 'red' }}>{error}</p>}
                                {success && <p style={{ color: 'green' }}>{success}</p>}
                            </div>
                        </Container>
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}
