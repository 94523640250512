










        import { Container, Row, Col } from 'react-bootstrap';
import image from '../../assets/image/searchimage.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Privacy() {
    const [contactData, setContactData] = useState(null);
    const [description, setDescription] = useState('');
    console.warn(description)
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const onSubmit = async () => {
        try {
            let url = `https://merividhya.in/Admin/api/get-privacy-policy-data`;
            const response = await axios.post(url);
            const responseData = response.data;

            // Log the entire response to ensure it's as expected
            console.log("API Response:", responseData);

            if (responseData && responseData.data) {
                const title = responseData.data.title;
                let description = responseData.data.description;
                setDescription(description);


                // Log the original description
                console.log("Original description:", description);

                // Filter out the specific paragraph
                const unwantedText = "It Uses A Dictionary Of Over 200 Latin Words, Combined With A Handful Of Model Sentence Structures, To Generate Lorem Ipsum Which Looks The Generated Lorem Ipsum Is Therefore Always Free From Repetition";
                description = description.replace(`<p>${unwantedText}</p>`, '');

                // Log the modified description
                console.log("Modified description:", description);

                setTitle(title);
            } else {
                setError('No data found');
            }

            setContactData(responseData);
        } catch (error) {
            setError('There was a problem with the fetch operation.');
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        onSubmit();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div id="carrer">
            <div className="banner-part sub-page-banner">
                <div className="sub-banner">
                    <Container>
                        <h1>{title}</h1>
                    </Container>
                </div>
            </div>
            <section className='margin-top-bottom'>
                <Container>
                    <Row className="align-items-center">
                        <Col md={6}>
                            {description ? (
                                <div dangerouslySetInnerHTML={{ __html: description }} />
                            ) : (
                                <div>No description available</div>
                            )}
                        </Col>
                        <Col md={6}>
                            <div className="careerimage">
                                <img src={image} alt="Career" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
}
