import React, { useState } from 'react'
import { FaUser } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaGooglePlusG } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import {Link} from 'react-router-dom'
import { FaArrowRightLong } from "react-icons/fa6";
import loginimage from '../../assets/image/login.png'
import herocircle from '../../assets/image/hero-1-circle.png'
import heroshape from '../../assets/image/hero-1-shape.png'
import shape from '../../assets/image/shape.png'
import { Container, Row, Col, Card,Form } from 'react-bootstrap';
export default function Login() {
  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;
  return (
    <main id="Login">
      <div className="banner-part sub-page-banner">
        <div className="sub-banner">
          <div className="container">
            <h1> Login </h1>
            <ul className="d-flex justify-content-center list-unstyled">
            <li>Explore, enhance & excel your school journey with ALTS (Meri Vidhaya Learning & Teaching Solution)</li>
            </ul>
          </div>
        </div>
        {/* <img src={subbanner} alt="sub" /> */}
      </div>
      <section className="contact-area section--padding position-relative">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="imagelogin">
                <div className="headingschool">
                   <h3>Start Your Journey With <span>Meri Vidhaya</span></h3>
                </div>
                <img src={loginimage} />
                <div className="hero__shape">
                    <img src={herocircle} className='hero-1-circle'/>
                    <img src={heroshape} className='hero-1-shape'/>
                    <img src={shape} className='hero-2-shape'/>
                </div>

              </div>
            
            </Col>
            <Col md={6}>
              <Card className="card-item">
                <Card>
                  <Card.Title className="text-center fs-24 lh-35 pb-4">
                    Login to Your Account!
                  </Card.Title>
                  <div className="section-block" />
                  <Form method="post" className="pt-4">
                    <div className="d-flex flex-wrap align-items-center pb-4">
                      <button type="button" className="btn theme-btn flex-grow-1 mx-2 mb-2">
                        <FaGooglePlusG className='mr-2' />

                        Google
                      </button>
                      <button type="button" className="btn theme-btn flex-grow-1 mx-2 mb-2">
                        <FaFacebookF className="mr-2" />
                        Facebook
                      </button>
                      <button type="button" className="btn theme-btn flex-grow-1 mx-2 mb-2">
                        <FaTwitter className="mr-2" />
                        Twitter
                      </button>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="icon-element d-flex justify-content-center  icon-element-md fs-25 shadow-sm">
                        Or
                      </div>
                    </div>
                    <div className="input-box">
                      <label className="label-text">Email or Username</label>
                      <div className="form-group  position-relative">
                        <input
                          className="form-control form--control"
                          type="text"
                          name="name"
                          placeholder="Email or Username"
                        />
                        <FaUser className='input-icon' />
                      </div>
                    </div>
                    {/* end input-box */}
                    <div className="input-box position-relative">
                      <label className="label-text">Password</label>
                      <div className="input-group mb-3">
                        <RiLockPasswordFill className='input-icon' />
                        <input
                          className="form-control form--control password-field"
                          name="password"
                          placeholder="Password"
                          type={changePassword ? "password" : "text"}
                          autoComplete="current-password"
                        />

                        <div className="input-group-append">
                          <button className="btn theme-btn theme-btn-transparent toggle-password" onClick={() => {
                            setChangePassword(changeIcon);
                          }}
                            type="button">
                            {changeIcon ? <FaEye /> : <FaEyeSlash />}
                          </button>


                        </div>
                      </div>
                    </div>
                    {/* end input-box */}
                    <div className="btn-box">
                      <div className="d-flex align-items-center justify-content-between pb-4">
                        <div className="custom-control custom-checkbox fs-15">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="rememberMeCheckbox"
                            required=""
                          />
                          <label
                            className="custom-control-label custom--control-label"
                            htmlFor="rememberMeCheckbox"
                          >
                            Remember Me
                          </label>
                        </div>
                        {/* end custom-control */}
                        <a href="recover.html" className="btn-text">
                          Forgot my password?
                        </a>
                      </div>
                      <button className="btn theme-btn d-flex justify-content-center w-100 align-items-center" type="button">
                        Login Account <FaArrowRightLong />

                      </button>
                      <p className="fs-14 pt-2 text-center">
                        Don't have an account?{" "}
                        <Link to="/Register" className="text-color hover-underline">
                          Register
                        </Link>
                      </p>
                    </div>
                    {/* end btn-box */}
                  </Form>
                </Card>
                {/* end card-body */}
              </Card>
              {/* end card */}
            </Col>
            {/* end col-lg-7 */}
          </Row>
          {/* end row */}
        </Container>
        {/* end container */}
      </section>


    </main >
  )
}
