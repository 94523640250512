import { Container, Row, Col } from 'react-bootstrap'
import image from '../../assets/image/searchimage.png';
import Logo from '../../assets/image/Logo.png';
import { MdCurrencyRupee } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import play from '../../assets/image/pl.png'
import React, { useState, useEffect } from 'react';
import axios from 'axios';


export default function Carrer() {

    const [joinOurTeamData, setJoinOurTeamData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios
            .post('https://merividhya.in/Admin/api/super-front-join-our-team')
            .then((response) => {
                if (response.data.status === 1) {
                    setJoinOurTeamData(response.data.JoinOurTeamData);
                } else {
                    // setError('Failed to fetch data.');
                }
            })
            .catch((error) => {
                // setError('Error fetching data: ' + error.message);
            });
    }, []);


    // const [clientss, setClientss] = useState([]);
    // const [headerDescription, setHeaderDescription] = useState('');
    const [VacanciesData, setVacanciesData] = useState('');
    const [url, seturl] = useState('');
    console.warn(url)


    useEffect(() => {
        axios.post('https://merividhya.in/Admin/api/super-front-vacancies')
            .then(response => {
                console.log('Response received:', response)
                const respons = response.data.url
                //   alert(respons)
                seturl(respons)

                if (response.data.status === 1) {

                    const VacanciesData = response.data.VacanciesData
                    setVacanciesData(VacanciesData)
                    // const urll = response.data.url;
                    // setImageUrl(urll);
                    // setClientss(response.data.data);
                    // setHeaderDescription(response.data.HeaderDescription.heading_desc);
                } else {
                    console.error('Failed to fetch data, status:', response.data.status);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);










    return (
        <div id="carrer">
            <div className="banner-part sub-page-banner">
                <div className="sub-banner">
                    <Container>
                        <h1> Career</h1>
                        <ul className="d-flex justify-content-center list-unstyled">
                            {/* <li> Lorem ipsum dolor sit amet </li> */}
                        </ul>
                    </Container>
                </div>
                {/* <img src={subbanner} alt="sub" /> */}
            </div>
            <section className='margin-top-bottom'>
                <Container>
                    <Row className="align-items-center">
                        {error && <p>{error}</p>}
                        {joinOurTeamData.length > 0 ? (
                            joinOurTeamData.map((item) => (
                                <React.Fragment key={item.id}>
                                    <Col md={6} >
                                        <h2>{item.title}</h2>
                                        <p dangerouslySetInnerHTML={{ __html: item.descriptions }} />
                                    </Col>
                                    <Col md={6}>
                                        <div className="careerimage">
                                            <img
                                                src={`https://merividhya.in/Admin/public/backend/uploads/joinourteam/${item.image}`}
                                                alt={item.title}
                                                style={{ maxWidth: '100%' }}
                                            />
                                        </div>
                                    </Col>
                                </React.Fragment>
                            ))
                        ) : (
                            <Col>
                                <p>No data available.</p>
                            </Col>
                        )}
                    </Row>
                    <div className="positonjobs">
                        <center>
                            <h3>Open Position</h3>
                            <p className="w-75 mx-auto">He are a team of people who genuinely want to simplify the burden of school management and make studying interactive and fun</p>
                        </center>
                        <Row>
                            <Col md={12}>
                                <div className="utf-listings-container-part compact-list-layout margin-top-35">
                                    {VacanciesData && VacanciesData.map(client => (
                                        <div className="utf-job-listing">
                                            <div className="utf-job-listing-details ">
                                                <div className="school_logo">
                                                    <img src={`${url}/${client.image}`} className="img-fluid" alt="Empower" />
                                                </div>

                                                <div className="utf-job-listing-description">
                                                    <span className="dashboard-status-button utf-job-status-item green">
                                                        <i className="icon-material-outline-business-center" />                                                 {client.in_office}
                                                    </span>
                                                    <h3 className="utf-job-listing-title">
                                                        {/* Experience : 2 to 3 years of software development */}
                                                        {client.experience}

                                                        <span
                                                            className="utf-verified-badge"
                                                            title="Verified Employer"
                                                            data-tippy-placement="top"
                                                        />
                                                    </h3>
                                                    <div className="utf-job-listing-footer">
                                                        <ul>
                                                            <li>
                                                                <i className="icon-feather-briefcase" /> {client.position_name}

                                                            </li>
                                                            <li>

                                                                <span><MdCurrencyRupee /></span>   {client.salary}
                                                            </li>
                                                            <li>
                                                                <MdLocationOn />

                                                                {client.open_location}
                                                            </li>
                                                            {/* <li>
                                                            <FaRegClock />

                                                            15 Minute Ago
                                                        </li> */}
                                                        </ul>
                                                    </div>
                                                    <p className="text-dark">
                                                        <div dangerouslySetInnerHTML={{ __html: client.descriptions }} />



                                                    </p>
                                                </div>
                                                <span className="bookmark-icon" />

                                            </div>
                                        </div>
                                    ))}






                                </div>

                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>


        </div>
    )
}
