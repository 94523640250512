import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import tree from '../../assets/image/tree.png';
export default function Treestruture() {
    return (
        <main id="tree" className="bg-education-century">
            <Container>
                <Row>
                   <div className="bg-i"></div>
                    <Col md={8} className='offset-md-4 offset-sm-4'>
                    <div className='treestructure'>
                    <div>
                        <ul class="tree vertical">
                            <li>
                                <div>Features</div>
                                <ul>
                                    <li>
                                        <div>Core Modules</div>
                                        <ul>
                                            <li>
                                                <div>Admission Management</div>
                                                <ul>
                                                    <li>
                                                        <div>Attendance Management</div>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <div>Certificates Management</div>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <div>Conveyance Management</div>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <div>Payroll Management</div>
                                                    </li>
                                                </ul>
                                                
                                               
                                            </li>
                                            <li>
                                                <div>Student Management </div>
                                                <ul>
                                                    <li>
                                                        <div>Time Table</div>
                                                    </li>
                                                </ul>
                                                <ul>

                                                    <li>
                                                        <div>Fee Management</div>
                                                     
                                                        <ul>
                                                            <li>
                                                                <div>Calender/Notice / Circular</div>
                                                            </li>
                                                        </ul>
                                                            <ul>
                                                    <li>
                                                        <div>Profit & Loss Management</div>
                                                    </li>
                                                </ul>
                                                      
                                                        
                                                    </li>
                                                </ul>

                                            </li>
                                        </ul>




                                    </li>

                                    <li>
                                        <div>Integrations</div>
                                        <ul>
                                            <li>
                                                <div>SMS / Email</div>
                                                <ul>
                                                    <li>
                                                        <div>Teachers App</div>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <div>Student App</div>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <div>Online Payment</div>
                                                    </li>
                                                </ul>
                                            </li>

                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                    </Col>
                </Row>
                
            </Container>

        </main>
    )
}
