




import React, { useState, useEffect } from 'react'
import axios from "axios";

import { Container, Row, Col } from 'react-bootstrap';
import banner2 from '../../assets/image/banner-2-shape-5.png'
import banner3 from '../../assets/image/banner-2-shape-2.png'
import banner4 from '../../assets/image/banner-2-shape-3.png'
import imageteacher from '../../assets/image/imageteacher.png'
import Projectcounter from '../page/Projectcounter'
import AboutusHome from '../page/AboutusHome'
import Educationcentury from '../page/Educationcentury'
import Empowerstudent from '../page/Empowerstudent'
import Downlaodapplication from '../page/Downlaodapplication'
import Treestruture from '../page/Treestruture'
import Getintouch from '../page/Getintouch'
import Blog from '../page/Blog'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import play from '../../assets/image/logoplaystrore.webp'
import about1 from '../../assets/image/about.jpg'






import Testimonial from '../page/Testimonial'
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Space,
  Button,
  Select,
} from 'antd';
const { Option } = Select;


export default function Home() {


  const blockInvalidChar = (e) => ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", , '+', '-', '.'].includes(e.key) && e.preventDefault();
  const [users, setUsers] = useState("")
  const [amount, setamount] = useState("")
  const [discodet, setdiscodet] = useState("")
  const [copecode, setcopecode] = useState("")


  const [school, setschool] = useState("")
  const [State, setState] = useState("")
  const [City, setCity] = useState("")
  const [phone, setphone] = useState("")
  const [email, setemail] = useState("")
  const [empcode, setempcode] = useState("")
  const [address, setaddress] = useState("")
  // console.warn(discodet)





  const onInputschool = (e) => {
    setschool(e.target.value);
  };
  const onchneaddress = (e) => {
    setaddress(e.target.value);
  };

  const onInputState = (e) => {
    setState(e.target.value);
  };


  const onInputCity = (e) => {
    setCity(e.target.value);
  };

  // const onchegephone = (e) => {
  //   setphone(e.target.value);
  //   const trimmedValue = inputValue.slice(0, 10); // Extract the first 10 characters

  // // Update the phone state with the first 10 characters
  // setphone(trimmedValue);
  // };+
  const onchegephone = (e) => {
    setphone(e.target.value);

    const inputValue = e.target.value;
    const trimmedValue = inputValue.slice(0, 10); // Extract the first 10 characters

    // Update the phone state with the first 10 characters
    setphone(trimmedValue);
  };
  const onchngeemail = (e) => {
    setemail(e.target.value);
  };
  const onchnempcode = (e) => {
    setempcode(e.target.value);
  };





















  useEffect(() => {
    // onSubmit();
  }, []);

  const onSubmit = async () => {

    if (school == "" || State == "" || City == "" || phone == "" || email == "" || address == "") {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: 'Please fill out the required fields',
        timer: 1000,
        icon: 'error'
      });
      return;
    }
    // window.location.href = '/Register'; 
    // Replace '/success-url' with the URL you want to navigate to


    if (phone.length !== 10) {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: 'Phone number must be 10 digits',
        timer: 2000,
        icon: 'error'
      });
      return;
    }




    let url = `https://merividhya.in/Admin/api/create-school-request`;
    const formData = new FormData();

    formData.append("school_name", school);
    formData.append("state", State);
    formData.append("city", City);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("empcode", empcode);
    formData.append("address", address);
    const response = await axios.post(url, formData);
    const responseData = response.data;
    // const discontt = responseData.amount;
    // setdiscodet(discontt);
    // console.warn(discontt)
    // alert(discodet)

    const MySwal = withReactContent(Swal);
    if (responseData.success === "1") {
      console.warn(responseData)
      MySwal.fire({
        title: responseData.message,
        icon: 'error',



        timer: 2500
      }).then((result) => {
      });
    } else {
      MySwal.fire({
        title: responseData.message,
        timer: 2000,
        icon: 'success',
      });
    }
    window.location.href = '/';

  };



  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}

      >
        <Option value="86" Selected>+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );
  return (
    <>
      <div className='Home' id="Home">

        <Container Fluid>
          <Row>
            <Col md={7} lg={7} xs={12} sm={12} className="position-relative">

              <div className="HeaderContent">
                <div className="hero-banner-two__shape5 wow fadeInUp animated"><img src={banner2} alt="banner2" /></div>
                <div className="hero-banner-two__shape2 wow fadeInUp animated"><img src={banner3} alt="eduact" /></div>
                <div className="hero-banner-two__shape3 wow fadeInUp animated"><img src={banner4} alt="eduact" /></div>
                <h2>Start Your journey With <strong>MeriVidhya</strong></h2>
                <p>Explore, enhance & excel your school journey with Meri Vidhya - The Best Online ERP Platform of 2024</p>
              </div>
            </Col>
            <Col md={5} lg={5} xs={12} sm={12}>

              <div
                className="about-two__thumb__one eduact-tilt"
                data-tilt-options='{ "glare": false, "maxGlare": 0, "maxTilt": 2, "speed": 700, "scale": 1 }'
                style={{
                  willChange: "transform",
                  transform: "perspective(300px) rotateX(0deg) rotateY(0deg)"
                }}
              >
                <img
                  src={imageteacher}
                  alt="eduact"
                  style={{ borderRadius: '10px' }}
                />

              </div>

            </Col>
          </Row>
          <Projectcounter />
        </Container>
      </div>
      <AboutusHome />
      <Educationcentury />

      <Empowerstudent />
      <Treestruture />
      <Downlaodapplication />
      <Blog />
      <Testimonial />
      <Getintouch />
    </>
  )
}

