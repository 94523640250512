import { Container, Row, Col } from 'react-bootstrap';
import imageteacher from '../../assets/image/imageteacher.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Empowerstudent() {

    const [descriptionempower, setDescriptionempower] = useState('');
    const [titleempower, settitleempower] = useState('');
    const [image, setimage] = useState('');
    const [url, setImageUrl] = useState('');


    const removeHtmlTags = (text) => {
        return text.replace(/<\/?p>/g, ''); // Remove <p> and </p> tags
    };

    const empower = async () => {
        try {
            let url = `https://merividhya.in/Admin/api/super-admin-empower-students`;

            const response = await axios.post(url);
            console.warn(response);

            // Accessing the response data
            const responseData = response.data;
            const urlll = responseData.url; // Base URL for images
            setImageUrl(urlll)
            
            // alert(urlll);

            // Check if the data and data array exist
            if (responseData && responseData.data && responseData.data.length > 0) {
                const description = responseData.data[0].description; // Assuming there's only one item in the array
                const descriptiontitle = responseData.data[0].title; // Assuming there's only one item in the array
                const setimageee = responseData.data[0].image; // Assuming there's only one item in the array
              

                // Clean the description from <p> tags
                const cleanedDescription = removeHtmlTags(description);
                setimage(setimageee)
                setDescriptionempower(cleanedDescription);
                settitleempower(descriptiontitle);

                // Log the description
                console.log("Description:", cleanedDescription);
            } else {
                // Handle case where there's no data
            }

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    useEffect(() => {
        empower();
    }, []);

    return (
        <div id="Empowerstudent">
            <Container>
                <Row className="align-items-center justify-content-center">
                    <Col md={6}>
                        <div className="contentempower">
                            <h3 className='text-center fw-bold headingfortittle'>{titleempower}</h3>
                            {/* <p>{descriptionempower}</p> */}
                            <p dangerouslySetInnerHTML={{ __html: descriptionempower }} />

                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="imageimageteacher">
                           
                        {image 
                ? <img src={`${url}/${image}`} className="img-fluid" alt="Empower" />
                : <img src={imageteacher} className="img-fluid" alt="Fallback" />
            }
                            </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
