import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import $ from "jquery";
import axios from 'axios';

export default function ProjectCounter() {
  const [schoolCount, setSchoolCount] = useState("");
  const [teacherCount, setTeacherCount] = useState("");
  const [studentCount, setStudentCount] = useState("");
  const [pinCodeCount, setPinCodeCount] = useState("");

  const fetchData = async () => {
    try {
      let url = `https://merividhya.in/Admin/api/super-school-covered`;
      const response = await axios.post(url);
      setSchoolCount(response.data.schoolCount);
      setTeacherCount(response.data.TeacherCount);
      setStudentCount(response.data.StudentCount);
      setPinCodeCount(response.data.pin_codeCount);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const jQuerySimpleCounter = (element, options) => {
      const settings = {
        start: 0,
        easing: 'swing',
        duration: 400,
        complete: '',
        ...options,
      };

      const thisElement = element;

      $({ count: settings.start }).animate({ count: settings.end }, {
        duration: settings.duration,
        easing: settings.easing,
        step: function () {
          const mathCount = Math.ceil(this.count);
          thisElement.text(mathCount);
        },
        complete: settings.complete,
      });
    };

    if (schoolCount) {
      jQuerySimpleCounter($('#number1'), { end: schoolCount, duration: 3000 });
    }
    if (teacherCount) {
      jQuerySimpleCounter($('#number2'), { end: teacherCount, duration: 3000 });
    }
    if (studentCount) {
      jQuerySimpleCounter($('#number3'), { end: studentCount, duration: 2000 });
    }
    if (pinCodeCount) {
      jQuerySimpleCounter($('#number4'), { end: pinCodeCount, duration: 2500 });
    }
  }, [schoolCount, teacherCount, studentCount, pinCodeCount]);

  return (
    <div id="projectcounter">
      <Container>
        <Row>
          <Col md={6} xs={6} lg={3}>
            <div className="counter">
              <div className="item wow fadeInUpBig animated animated counter_new" data-number={600} style={{ visibility: "visible" }}>
                <p id="number1" className="number_counter">
                  <span>{schoolCount}</span>
                </p>
                <h6>SCHOOLS COVERED</h6>
              </div>
            </div>
          </Col>
          <Col md={6} xs={6} lg={3}>
            <div className="item wow fadeInUpBig animated animated text-center counter_new" data-number={55} style={{ visibility: "visible" }}>
              <p id="number2" className="number_counter">
                {teacherCount}
              </p>
              <h6>TEACHERS</h6>
            </div>
          </Col>
          <Col md={6} xs={6} lg={3}>
            <div className="item wow fadeInUpBig animated animated counter_new" data-number={359} style={{ visibility: "visible" }}>
              <p id="number3" className="number_counter">
                {studentCount}
              </p>
              <h6>STUDENTS</h6>
            </div>
          </Col>
          <Col md={6} xs={6} lg={3}>
            <div className="item wow fadeInUpBig animated animated counter_new" data-number={246} style={{ visibility: "visible" }}>
              <p id="number4" className="number_counter">
                {pinCodeCount}
              </p>
              <h6>CITIES</h6>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
