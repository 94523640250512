
import React, { useState, useEffect } from 'react'
import axios from "axios";

import { Container, Row, Col } from 'react-bootstrap';
import banner2 from '../../assets/image/banner-2-shape-5.png'
import banner3 from '../../assets/image/banner-2-shape-2.png'
import banner4 from '../../assets/image/banner-2-shape-3.png'
import Projectcounter from '../page/Projectcounter'
import AboutusHome from '../page/AboutusHome'
import Educationcentury from '../page/Educationcentury'
import Empowerstudent from '../page/Empowerstudent'
import Downlaodapplication from '../page/Downlaodapplication'
import Treestruture from '../page/Treestruture'
import Getintouch from '../page/Getintouch'
import Blog from '../page/Blog'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import play from '../../assets/image/logoplaystrore.webp' 


import Testimonial from '../page/Testimonial'
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Space,
  Button,
  Select,
} from 'antd';
const { Option } = Select;


export default function Scholrigster() {


  const blockInvalidChar = (e) => ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", , '+', '-', '.'].includes(e.key) && e.preventDefault();
  const [users, setUsers] = useState("")
  const [amount, setamount] = useState("")
  const [discodet, setdiscodet] = useState("")
  const [copecode, setcopecode] = useState("")


  const [school, setschool] = useState("")
  const [State, setState] = useState("")
  const [City, setCity] = useState("")
  const [phone, setphone] = useState("")
  const [email, setemail] = useState("")
  const [empcode, setempcode] = useState("")
  const [address, setaddress] = useState("")
  // console.warn(discodet)

  // alert(selectedPlanId)





  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  // console.warn(subscriptionPlans)
  const [selectedPlan, setSelectedPlan] = useState(null);
  console.warn(selectedPlan)

  // alert(selectedPlan)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
      // Fetch subscription plans from the API
      const fetchSubscriptionPlans = async () => {
          try {
              const response = await axios.post('https://merividhya.in/Admin/api/school-subscriptions-front-plan-lists');
              console.warn(response)
              if (response.data.status === 1) {
                  setSubscriptionPlans(response.data.data);
              } else {
                  setError('Failed to fetch subscription plans.');
              }
          } catch (err) {
              setError('Error fetching subscription plans.');
          } finally {
              setLoading(false);
          }
      };

      fetchSubscriptionPlans();
  }, []);

  const handleSelectChange = (event) => {
    // alert(handleSelectChange)
      const selectedId = parseInt(event.target.value, 10);
      console.warn(selectedId)
      const selected = subscriptionPlans.find(plan => plan.id === selectedId);
      setSelectedPlan(selected);
      // localStorage.clear()

      const selectedPlanId = event.target.value;

      // Save the selected plan ID to local storage
      localStorage.setItem('selectedPlanId', selectedPlanId);
  };



  // const handlePlanClick = () => {
  //   if (selectedPlan) {
  //     alert(`Selected plan ID: ${selectedPlan.id}`);
  //     // or use console.log
  //     // console.log(`Selected plan ID: ${selectedPlan.id}`);
  //   }
  // };




  const onInputschool = (e) => {
    setschool(e.target.value);
  };
  const onchneaddress = (e) => {
    setaddress(e.target.value);
  };

  const onInputState = (e) => {
    setState(e.target.value);
  };


  const onInputCity = (e) => {
    setCity(e.target.value);
  };

  // const onchegephone = (e) => {
  //   setphone(e.target.value);
  //   const trimmedValue = inputValue.slice(0, 10); // Extract the first 10 characters

  // // Update the phone state with the first 10 characters
  // setphone(trimmedValue);
  // };+
  const onchegephone = (e) => {
    setphone(e.target.value);

    const inputValue = e.target.value;
    const trimmedValue = inputValue.slice(0, 10); // Extract the first 10 characters

    // Update the phone state with the first 10 characters
    setphone(trimmedValue);
  };
  const onchngeemail = (e) => {
    setemail(e.target.value);
  };
  const onchnempcode = (e) => {
    setempcode(e.target.value);
  };



  useEffect(() => {
    // onSubmit();
  }, []);

  const onSubmit = async () => {
    const selectedPlanId = localStorage.getItem('selectedPlanId');
    // alert(selectedPlanId)


    if (school == "" ||  selectedPlan == "" ||       State == "" || City == "" || phone == "" || email == "" || address == "") {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: 'Please fill out the required fields',
        timer: 1000,
        icon: 'error'
      });
      return;
    }


     if (!selectedPlanId) {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: 'Please select a subscription plan',
            timer: 2000,
            icon: 'error'
        });
        return;
    }
    // window.location.href = '/Register'; 
    // Replace '/success-url' with the URL you want to navigate to


    if (phone.length !== 10) {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: 'Phone number must be 10 digits',
        timer: 2000,
        icon: 'error'
      });
      return;
    }




    let url = `https://merividhya.in/Admin/api/create-school-request`;
    const formData = new FormData();

    formData.append("school_name", school);
    formData.append("state", State);
    formData.append("city", City);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("empcode", empcode);
    formData.append("address", address);
    formData.append("plan_cat_id", selectedPlanId);
    const response = await axios.post(url, formData);
    const responseData = response.data;
    // const discontt = responseData.amount;
    // setdiscodet(discontt);
    // console.warn(discontt)
    // alert(discodet)

    const MySwal = withReactContent(Swal);
    if (responseData.success === "1") {
      console.warn(responseData);
      MySwal.fire({
        title: responseData.message,
        icon: 'error',
        timer: 2500
      }).then((result) => {
        window.location.href = '/';
        localStorage.clear();

      });
    } else {
      MySwal.fire({
        title: responseData.message,
        timer: 2500,
        icon: 'success'
      }).then((result) => {
        window.location.href = '/';
        localStorage.clear();

      });
    }
  };


  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}

      >
        <Option value="86" Selected>+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );
  return (
    <>
      <div className='Home' id="Home">

        <Container Fluid>
          <Row>

            <Col md={12} lg={12} xs={12} sm={12}>

              <div className='Form-container'>
                <h3 className='text-center'>Request <span>Information</span></h3>
                <Form
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  layout="horizontal">
                  <Row>
                    <Col md={6}>
                      <Form.Item label="School Name" className='labelform'>
                        <Input value={school} onChange={onInputschool} placeholder="Enter School Name" className='form-control' />
                      </Form.Item>

                    </Col>
                    <Col md={6}>
                      <Form.Item label="Phone  Number" className='labelform'>
                        <Input value={phone} onKeyDown={blockInvalidChar}
                          onChange={onchegephone} type="number" placeholder="Enter Phone Number :9928026055" className='form-control' />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        value={email}
                        onChange={onchngeemail}
                        className='labelform'
                        name="email"
                        label="E-Mail"
                        rules={[
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          },
                          {
                            required: true,
                            message: 'Please input your E-mail!',
                          },
                        ]}
                      >
                        <Input className='form-control' placeholder="Enter E-Mail" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item label="State" className='labelform'>
                        <Input value={State} onChange={onInputState} placeholder="Enter State  Name" className='form-control' />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item label="City" className='labelform'>
                        <Input value={City} onChange={onInputCity} placeholder="Enter City  Name" className='form-control' />
                      </Form.Item>
                    </Col>
                   
                    <Col md={6}>
                      <Form.Item label=" Employee code" className='labelform'>
                        <Input value={empcode} onChange={onchnempcode} placeholder="Enter Employee code " className='form-control' />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        value={address}
                        onChange={onchneaddress}
                        className='labelform'
                        name="textarea"
                        label="Address "
                        rules={[
                          {
                            type: 'Address',
                            message: 'The input is not valid  Address!',
                          },
                          {
                            required: true,
                            message: 'Please input your Address!',
                          },
                        ]}
                      >
                        <textarea className='form-control' placeholder="Enter Address" />
                      </Form.Item>
                    </Col>

                    <Col md={6}>
                      <Form.Item label="Select a Subscription Plan" className='labelform'>
                      <select class="form-select fw-semibold" aria-label="Default select example"  onChange={handleSelectChange} value={selectedPlan ? selectedPlan.id : ''}>
                      <option value="" disabled>Select  Plan</option>
                      {subscriptionPlans.map(plan => (
                    <option key={plan.id} value={plan.id}>
                 {plan.name} { plan.amount } Rs.*
                {plan.name !== "1 Year" && ` ( ${plan.bonus_years } Year extra Free ) `}
                           </option>
                                  ))}


                      </select>
                      </Form.Item>
                    </Col>

                    <div>                
                  </div>
                    <Col md={12} className='d-flex justify-content-end'>
                      <Form.Item
                        style={{ width: "200px" }}
                      >
                        <Space className='d-block btn-demo'>
                          <Button className='btn w-100 btn-demo-custum' htmlType="submit" onClick={onSubmit}>
                            Book Demo
                          </Button>
                          <p className="fs-14 pt-2 text-center">
                            Do You have an account?{" "}
                            <a href="https://merividhya.in/Admin/school" className="text-color hover-underline">
                              Login
                            </a>
                          </p>

                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>


              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12} lg={12} xs={6} sm={6} className="position-relative mt-5">

              <div className="HeaderContent">
                <div className="hero-banner-two__shape5 wow fadeInUp animated"><img src={banner2} alt="banner2" /></div>
                <div className="hero-banner-two__shape2 wow fadeInUp animated"><img src={banner3} alt="eduact" /></div>
                <div className="hero-banner-two__shape3 wow fadeInUp animated"><img src={banner4} alt="eduact" /></div>
                <h2>Start Your journey With <strong>MeriVidhya</strong></h2>
                <p>Explore, enhance & excel your school journey with Meri Vidhya - The Best Online ERP Platform of 2024</p>
              </div>
            </Col>

          </Row> */}
          {/* <Projectcounter /> */}
        </Container>
      </div>
      {/* <AboutusHome />
      <Educationcentury />

      <Empowerstudent />
      <Treestruture />
      <Downlaodapplication />
      <Blog />
      <Testimonial />
      <Getintouch /> */}
    </>
  )
}

