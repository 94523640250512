import React, { useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import $ from "jquery";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import sliderimage1 from '../../assets/image/sliderimage1.png'
export default function Educationcentury() {
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        swipeToSlide: true,
        vertical: true,
        verticalSwiping: true,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 1000

    };

    const handleWheel = (e) => {
        if (e.deltaY > 0) {
            slider.slickNext();
        } else {
            slider.slickPrev();
        }
    };

    let slider;




    return (
        <div>

            <div id="Educationcentury" className='section-top-bottom mt-0'>
                <div className="bg-education-century">
                    <Container>
                        <div className="section-padding-top-bottom text-white">
                            <div className="text-center heading_tittle">
                                <h3>How Meri Vidhya </h3>
                                <h2>Empowers Schools </h2>
                            </div>
                            <div className="margin-top-bottom">

                                <div onWheel={handleWheel}>
                                    <Slider ref={(c) => (slider = c)} {...settings}>
                                        {/* Your carousel content here */}
                                     
                                            <div class='item'>
                                                <div className="items_Box">
                                                    <div className="bg-white card-new">

                                                        <div className="contentslider">

                                                            <Row className="align-items-center">
                                                                <Col md={6} lg={6} xs={7}>
                                                                    <div className="afterhover">
                                                                        <h3>Teachers</h3>
                                                                        <ul class="list-style text-left">
                                                                            <li className="d-flex">
                                                                                &nbsp; Built-in Lesson Plans</li>
                                                                            <li className="d-flex">
                                                                                &nbsp; Question Bank </li>
                                                                            <li className="d-flex">
                                                                                &nbsp; Adaptive Testing & Analysis Tools</li>
                                                                            <li className="d-flex">
                                                                                &nbsp; Online Attendance </li>
                                                                            <li className="d-flex">
                                                                                &nbsp; Online marks Entry </li>
                                                                            <li className="d-flex">
                                                                                &nbsp; Online Home Work </li>
                                                                        </ul>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6} lg={6} xs={5}>
                                                                    <div className="imagepoints">
                                                                        <img src={sliderimage1} />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                     
                                            <div class='item'>
                                                <div className="items_Box">
                                                    <div className="bg-white card-new">

                                                        <div className="contentslider">

                                                            <Row className="align-items-center">
                                                                <Col md={6} lg={6} xs={7}>
                                                                    <div className="afterhover">
                                                                        <h3>Parents / Students –</h3>
                                                                        <ul class="list-style text-left">
                                                                            <li className="d-flex"> Unlimited access to question bank</li>
                                                                            <li className="d-flex"> Homework</li>
                                                                            <li className="d-flex"> Online Assessment </li>
                                                                            <li className="d-flex"> Performance Analysis </li>
                                                                            <li className="d-flex"> Active Query Section </li>
                                                                            <li className="d-flex"> Apply different certificates online - Fee Certificates,
                                                                                Bonafide Certificates, Transfer Certficates</li>
                                                                            {/* <li className="d-flex"> Pay Fee online</li>
                                                                            <li className="d-flex"> Fee Receipt </li>
                                                                            <li className="d-flex"> Online Exam time table</li> */}
                                                                        </ul>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6} lg={6} xs={5}>
                                                                    <div className="imagepoints">
                                                                        <img src={sliderimage1} />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        
                                            <div class='item'>
                                                <div className="items_Box">
                                                    <div className="bg-white card-new">

                                                        <div className="contentslider">

                                                            <Row className="align-items-center">
                                                                <Col md={6} lg={6} xs={7}>
                                                                    <div className="afterhover">
                                                                        <h3>Schools</h3>
                                                                        <ul class="list-style text-left">
                                                                            <li className="d-flex"> School Enterprise Resource Planner </li>
                                                                            <li className="d-flex"> Online Admission</li>
                                                                            <li className="d-flex"> Academic year planning </li>
                                                                            <li className="d-flex"> Home Work </li>
                                                                            <li className="d-flex"> Circulars and Notice Regulation </li>
                                                                            <li className="d-flex">  Fee Manager </li>
                                                                            {/* <li className="d-flex"> Calender</li>
                                                                            <li className="d-flex"> Report Card</li>
                                                                            <li className="d-flex"> Student Management</li>
                                                                            <li className="d-flex">  Conveyance Management</li>
                                                                            <li className="d-flex"> Exam Manager </li>
                                                                            <li className="d-flex"> Attendance Manager </li>
                                                                            <li className="d-flex"> Account</li>
                                                                            <li className="d-flex"> Payroll</li> */}
                                                                        </ul>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6} lg={6} xs={5}>
                                                                    <div className="imagepoints">
                                                                        <img src={sliderimage1} />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                       
                                        {/* Add more slides as needed */}
                                    </Slider>
                                </div>

                            </div>
                        </div>
                    </Container>
                </div>
            </div>

        </div>
    )
}
