import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImQuotesRight } from "react-icons/im";

const Testimonial = () => {
  const [clientss, setClientss] = useState([]);
  const [headerDescription, setHeaderDescription] = useState('');
  const [url, setImageUrl] = useState('');

  useEffect(() => {
    axios.post('https://merividhya.in/Admin/api/super-admin-testimonials')
      .then(response => {
        console.log('Response received:', response);

        if (response.data.status === 1) {
          const urll = response.data.url;
          setImageUrl(urll);
          setClientss(response.data.data);
          setHeaderDescription(response.data.HeaderDescription.heading_desc);
        } else {
          console.error('Failed to fetch data, status:', response.data.status);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    margin: "15px",
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: "10px",
        }
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: "5px",
        }
      },
    ]
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
  };

  return (
    <div className='padding-top-bottom testimonial-section-five' id="blog">
      <Container>
        <Row className="justify-content-center">
          <Col xl={7} lg={10}>
            <div className="section-title text-center mb-50">
              <h2>Testimonial</h2>
              <p>{headerDescription}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Slider {...settings}>
            {clientss.map(client => (
              <Col lg={4} md={12} key={client.id}>
                <div className='item'>
                  <div className="testimonial-block-five">
                    <div className="inner-box">
                      <div className="text">
                        <span dangerouslySetInnerHTML={{ __html: truncateText(client.description, 100) }}></span>
                      </div>
                      <div className="author-info">
                        <div className="info-inner">
                          <div className="quote-icon"><ImQuotesRight /></div>
                          <div className="author-image">
                            <img src={`${url}/${client.image}`} className="img-fluid" alt="Empower" />
                          </div>
                          <h4>{client.name}</h4>
                          <div className="designation">{client.title}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Slider>
        </Row>
      </Container>
    </div>
  );
};

export default Testimonial;
