import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Component/Header/Header';
import Footer from './Component/Footer/Footer';
import Home from './Component/page/Home';
import Projectcounter from './Component/page/Projectcounter';
import Aboutus from './Component/page/Aboutus';
import Contactus from './Component/page/Contactus';
import Carrer from './Component/page/Carrer';
import Login from './Component/page/Login';
import Register from './Component/page/Register';
import Scholrigster from './Component/page/Scholrigster';
import TermsConditions from './Component/page/TermsConditions';
import Privacy from './Component/page/Privacy';
import RefundPolicy from './Component/page/RefundPolicy';
import PaymentPolicy from './Component/page/PaymentPolicy';
import EndUserLicenseAgreement from './Component/page/EndUserLicenseAgreement';
import Getintouch from './Component/page/Getintouch';

function App() {
  return (
  <>
      
    <BrowserRouter>
    
    <Routes>
   
       <Route
       path="/*"
       element={
         <div>
           <Header/>
           <Routes>
               <Route path="/" element={<Home/>}></Route>
               <Route path="/Projectcounter" element={<Projectcounter/>}></Route>
               <Route path="/Aboutus" element={<Aboutus/>}></Route>
               <Route path="/Contactus" element={<Contactus/>}></Route>
               <Route path="/Carrer" element={<Carrer/>}></Route>
               <Route path="/Login" element={<Login/>}></Route>
               <Route path="/Register" element={<Register/>}></Route>
               <Route path="/Scholrigster" element={<Scholrigster/>}></Route>
               <Route path="/TermsConditions" element={<TermsConditions/>}></Route>
               <Route path="/Privacy" element={<Privacy/>}></Route>
               <Route path="/RefundPolicy" element={<RefundPolicy/>}></Route>
               <Route path="/PaymentPolicy" element={<PaymentPolicy/>}></Route>
               <Route path="/EndUserLicenseAgreement" element={<EndUserLicenseAgreement/>}></Route>
               <Route path="/Getintouch" element={<Getintouch/>}></Route>
           </Routes>
           <Footer/>
         </div>
       }
       >
       </Route>
    </Routes>
    </BrowserRouter>
  </>
  );
}

export default App;
